import React, { useState, useEffect } from "react";
import { useSubheader } from "../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import axios from "axios";
import { privilegeURL, privilegeURLV2, imageURL, campaigns_id_privilage, privilegeImage, privilegeURL2 } from "../../../service/API.js";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import Switch from "@material-ui/core/Switch";
import JoditEditor from "./JoditEditor";
import { Link } from "react-router-dom";
import "./component.css";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import CropperImage from './CropperImage'
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Modal from "@material-ui/core/Modal";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Checkbox from '@material-ui/core/Checkbox';
import {
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../_metronic/_helpers"//"../../../../_metronic/_helpers";
import BootstrapTable from "react-bootstrap-table-next";
import { useSelector } from "react-redux";
import ImageResize from "image-resize";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from "moment";
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_metronic/_helpers'
import { DateTimePicker, KeyboardDateTimePicker } from "@material-ui/pickers";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
}));


export const CreatePrivilegeSettingToYou = (props) => {
  console.log('props', props);
  const suhbeader = useSubheader();
  const { user, authToken, accessTokenPrivilege } = useSelector((state) => state.auth);
  const classes = useStyles();
  suhbeader.setTitle("Create Privilege");
  const history = useHistory();

  const date_new = new Date();
  const cover_new_date = moment(date_new).format("YYYY-MM-DDTHH:mm")
  const [newDate, setNewDate] = useState(cover_new_date);
  const [name, setName] = useState();
  const [loading, setLoding] = useState(false);
  const [detail, setDetail] = useState();
  const [state, setState] = useState({
    is_active: false,
    is_delivery: false,
    is_lucky: false,
    allow_once: false,
    is_show_on_front: false,
  });

  const [stateItem_pic_path, setStateItem_pic_path] = useState({
    previewItem_pic_path: null,
    rawItem_pic_path: null,
    picURLItem_pic_path: null
  })
  const [stateItem_pic_path2, setStateItem_pic_path2] = useState({
    previewItem_pic_path: null,
    rawItem_pic_path: null,
    picURLItem_pic_path: null
  })
  const [namepicItem_pic_path, setNamepicItem_pic_path] = useState()
  const [namepicItem_pic_path2, setNamepicItem_pic_path2] = useState()
  const [item_pic_path, setItem_pic_path] = useState()
  const [item_pic_path2, setItem_pic_path2] = useState()
  const [stateItem_logo_path, setStateItem_logo_path] = useState({
    previewItem_logo_path: null,
    rawItem_logo_path: null,
    logoURLItem_logo_path: null
  })
  const [namelogoItem_logo_path, setNamelogoItem_logo_path] = useState()
  const [item_logo_path, setItem_logo_path] = useState()

  const [costs, setCosts] = useState([{ amount: 0, qty: 0, expire: 0 }]);
  const [tagData, setTagData] = useState([]);
  const [tagItem, setTagItem] = useState([]);

  const [point, setPoint] = useState();
  const [display_weight, setDisplayWeight] = useState();
  const [symbolsArr] = useState(["e", "E", "+", "-"])


  const [openEditCampaign, setOpenEditCampaign] = useState(false);
  const [itemCampaign, setItemCampaign] = useState();
  const [start_date_campaign, setstart_date_campaign] = useState();
  const [end_date_campaign, setend_date_campaign] = useState();
  const [period_limit_campaign, setperiod_limit_campaign] = useState();
  const [year_limit_campaign, setyear_limit_campaign] = useState();
  const [month_limit_campaign, setmonth_limit_campaign] = useState();
  const [week_limit_campaign, setweek_limit_campaign] = useState();
  const [week_day_limit_campaign, setweek_day_limit_campaign] = useState(null);
  const [day_limit_campaign, setday_limit_campaign] = useState();

  const [openEditMember, setOpenEditMember] = useState(false);
  const [itemMember, setItemMember] = useState();
  const [start_date_member, setstart_date_member] = useState();
  const [end_date_member, setend_date_member] = useState();
  const [period_limit_member, setperiod_limit_member] = useState();
  const [year_limit_member, setyear_limit_member] = useState();
  const [month_limit_member, setmonth_limit_member] = useState();
  const [week_limit_member, setweek_limit_member] = useState();
  const [week_day_limit_member, setweek_day_limit_member] = useState(null);
  const [day_limit_member, setday_limit_member] = useState();

  // const [prevActiveStep, setPrevActiveStep] = useState(0);

  const [openDialogModalTable, setOpenDialogModalTable] = useState(false);
  const [openDialogModalReTable, setOpenDialogModalReTable] = useState(false);

  const [startDateDisplay, setStartDateDisplay] = useState(null);
  const [endDateDisplay, setEndDateDisplay] = useState(null);
  const [startDateRedeem, setStartDateRedeem] = useState(null);
  const [endDateRedeem, setEndDateRedeem] = useState(null);

  const [dialogTable, setDialogTable] = useState([]);
  const [dialogTableRe, setDialogTableRe] = useState([]);

  const [id_res, setid_res] = useState(0);
  const [id_res_two, setid_res_two] = useState(0);
  const [length, setlength] = useState(0);
  const [amount, setamount] = useState(0);
  const [symbols, setsymbols] = useState(false);
  const [uppercase, setuppercase] = useState(false);
  const [numbers, setnumbers] = useState(false);
  const [lowercase, setlowercase] = useState(false);
  const [exchange, setExchange] = useState(false);
  const [location, setLocation] = useState('');
  const [step_deal, setStep_deal] = useState();
  const [expire, setExpire] = useState(5);

  //mon
  const [mons, setMon] = useState([{ day_of_week: 1, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedMon, setCheckedMon] = useState(false);
  const handleChangeCheckMon = (event) => {
    setCheckedError(false)
    setCheckedMon(!checkedMon);
  };
  //tue
  const [tues, setTue] = useState([{ day_of_week: 2, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedTue, setCheckedTue] = useState(false);
  const handleChangeCheckTue = (event) => {
    setCheckedError(false)
    setCheckedTue(!checkedTue);
  };
  //wed
  const [weds, setWed] = useState([{ day_of_week: 3, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedWed, setCheckedWed] = useState(false);
  const handleChangeCheckWed = (event) => {
    setCheckedError(false)
    setCheckedWed(!checkedWed);
  };
  //Thu
  const [thus, setThu] = useState([{ day_of_week: 4, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedThu, setCheckedThu] = useState(false);
  const handleChangeCheckThu = (event) => {
    setCheckedError(false)
    setCheckedThu(!checkedThu);
  };
  //Fri
  const [fris, setFri] = useState([{ day_of_week: 5, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedFri, setCheckedFri] = useState(false);
  const handleChangeCheckFri = (event) => {
    setCheckedError(false)
    setCheckedFri(!checkedFri);
  };
  //Sat
  const [sats, setSat] = useState([{ day_of_week: 6, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedSat, setCheckedSat] = useState(false);
  const handleChangeCheckSat = (event) => {
    setCheckedError(false)
    setCheckedSat(!checkedSat);
  };
  //Sun
  const [suns, setSun] = useState([{ day_of_week: 7, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedSun, setCheckedSun] = useState(false);
  const handleChangeCheckSun = (event) => {
    setCheckedError(false)
    setCheckedSun(!checkedSun);
  };
  const [checkedError, setCheckedError] = useState(false);


  //monRe
  const [monRes, setMonRe] = useState([{ day_of_week: 1, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedMonRe, setCheckedMonRe] = useState(false);
  const handleChangeCheckMonRe = (event) => {
    setCheckedErrorRe(false)
    setCheckedMonRe(!checkedMonRe);
  };
  //tueRe
  const [tueRes, setTueRe] = useState([{ day_of_week: 2, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedTueRe, setCheckedTueRe] = useState(false);
  const handleChangeCheckTueRe = (event) => {
    setCheckedErrorRe(false)
    setCheckedTueRe(!checkedTueRe);
  };
  //wedRe
  const [wedRes, setWedRe] = useState([{ day_of_week: 3, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedWedRe, setCheckedWedRe] = useState(false);
  const handleChangeCheckWedRe = (event) => {
    setCheckedErrorRe(false)
    setCheckedWedRe(!checkedWedRe);
  };
  //ThuRe
  const [thuRes, setThuRe] = useState([{ day_of_week: 4, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedThuRe, setCheckedThuRe] = useState(false);
  const handleChangeCheckThuRe = (event) => {
    setCheckedErrorRe(false)
    setCheckedThuRe(!checkedThuRe);
  };
  //FriRe
  const [friRes, setFriRe] = useState([{ day_of_week: 5, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedFriRe, setCheckedFriRe] = useState(false);
  const handleChangeCheckFriRe = (event) => {
    setCheckedErrorRe(false)
    setCheckedFriRe(!checkedFriRe);
  };
  //SatRe
  const [satRes, setSatRe] = useState([{ day_of_week: 6, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedSatRe, setCheckedSatRe] = useState(false);
  const handleChangeCheckSatRe = (event) => {
    setCheckedSatRe(!checkedSatRe);
  };
  //SunRe
  const [sunRes, setSunRe] = useState([{ day_of_week: 7, start_time: "", end_time: "", check_validate_time: false }]);
  const [checkedSunRe, setCheckedSunRe] = useState(false);
  const handleChangeCheckSunRe = (event) => {
    setCheckedErrorRe(false)
    setCheckedSunRe(!checkedSunRe);
  };
  const [checkedErrorRe, setCheckedErrorRe] = useState(false);
  const [check_upload, setCheck_upload] = useState(false);

  const [file, setFile] = useState();
  const [namefile, setNameFile] = useState("");
  const [isUpload, setIsUpload] = useState(false);
  const [progress, setProgress] = useState(0);

  // const [id_q, setid_q] = useState(0);
  const [start_date_q, setstart_date_q] = useState(null);
  const [end_date_q, setend_date_q] = useState(null);
  const [period_limit_q, setperiod_limit_q] = useState();
  const [year_limit_q, setyear_limit_q] = useState();
  const [month_limit_q, setmonth_limit_q] = useState();
  const [week_limit_q, setweek_limit_q] = useState();
  const [week_day, setweek_day] = useState(null);
  const [day_limit_q, setday_limit_q] = useState();

  const [campaignData, setCampaignData] = useState([]);

  // const [id_qm, setid_qm] = useState(0);
  const [start_date_qm, setstart_date_qm] = useState(null);
  const [end_date_qm, setend_date_qm] = useState(null);
  const [period_limit_qm, setperiod_limit_qm] = useState();
  const [year_limit_qm, setyear_limit_qm] = useState();
  const [month_limit_qm, setmonth_limit_qm] = useState();
  const [week_limit_qm, setweek_limit_qm] = useState();
  const [week_day_qm, setweek_day_qm] = useState(null);
  const [day_limit_qm, setday_limit_qm] = useState();
  const [memberData, setMemberData] = useState([]);
  const [campaigns_id_headder, setCampaigns_id_headder] = useState();
  const [deal_detail_condition, setdeal_detail_condition] = useState();
  const [deal_detail_promote, setdeal_detail_promote] = useState();
  const [type, setType] = useState("auto");

  const handleConditionChange = (message) => {
    setdeal_detail_condition(message)
  }

  const [users, setUsers] = useState(null);
  const [token, setToken] = useState(null);
  const AlertError = (message) => {
    Swal.fire("Privilege!", message, "error");
  };

  const handleChangeWeekDay = (type_name) => {
    setweek_day(type_name);
  };

  const handleChangeWeekDayCampaign = (type_name) => {
    setweek_day_limit_campaign(type_name);
  };

  const handleChangeWeekDayQm = (type_name) => {
    setweek_day_qm(type_name);
  };

  const handleChangeWeekDayMember = (type_name) => {
    setweek_day_limit_member(type_name);
  };

  useEffect(() => {
    setCampaigns_id_headder(campaigns_id_privilage);
    getTag(campaigns_id_privilage);
  }, []);

  const getTag = async (campaigns_id_privilage) => {
    const token = localStorage.getItem('Token')
    fetch(`${privilegeURL2}/getTagAll`, {
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      }
    })
      .then((response) => response.json())
      .then(async (res) => {
        var newState = [];
        if (res) {
          res.map((data) => {
            newState.push({ tag_name: data.tag_name, tag_id: data.id, topic: data.tagTopic.id, topic_name: data.tagTopic.tag_topic_name, tag_and_data: `${data.tagTopic.tag_topic_name} : ${data.tag_name}` });
          });
          setTagData(newState);
        } else {
          setTagData({ tag_name: "No data", tag_id: 0, topic: "No data", topic_name: "No data", tag_and_data: "No data" });
        }
      });
  };

  const columns_d = [
    {
      dataField: "day_of_week",
      text: "Day",
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, index) => {
        return (
          <>
            <span>
              {row.day_of_week === 1 ? "Monday" : ""}
              {row.day_of_week === 2 ? "Tuesday" : ""}
              {row.day_of_week === 3 ? "Wednesday" : ""}
              {row.day_of_week === 4 ? "Thursday" : ""}
              {row.day_of_week === 5 ? "Friday" : ""}
              {row.day_of_week === 6 ? "Saturday" : ""}
              {row.day_of_week === 7 ? "Sunday" : ""}
            </span>

          </>
        );
      },
    },
    {
      dataField: "start_time",
      text: "Time Start",
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "end_time",
      text: "Time End",
      headerStyle: (colum, colIndex) => {
        return { width: "150px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ]


  //Mon
  const handleMonsChange = (event) => {
    const _tempMons = [...mons];
    _tempMons[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempMons[event.target.dataset.id].start_time >= _tempMons[event.target.dataset.id].end_time) {
      _tempMons[event.target.dataset.id].check_validate_time = false;
      setCheckedError(false)
      setMon(_tempMons);
    } else {
      _tempMons[event.target.dataset.id].check_validate_time = true;
      setCheckedError(true)
      setMon(_tempMons);
    }
  };
  const addNewMons = () => {
    setCheckedError(false)
    setMon((prevMons) => [...prevMons, { day_of_week: 1, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveMonsFields = (index) => {
    const values = [...mons];
    values.splice(index, 1);
    setMon(values);
  };

  //Tue
  const handleTuesChange = (event) => {
    const _tempTues = [...tues];
    _tempTues[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempTues[event.target.dataset.id].start_time >= _tempTues[event.target.dataset.id].end_time) {
      _tempTues[event.target.dataset.id].check_validate_time = false;
      setCheckedError(false)
      setTue(_tempTues);
    } else {
      _tempTues[event.target.dataset.id].check_validate_time = true;
      setCheckedError(true)
      setTue(_tempTues);
    }
  };
  const addNewTues = () => {
    setCheckedError(false)
    setTue((prevTues) => [...prevTues, { day_of_week: 2, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveTuesFields = (index) => {
    const values = [...tues];
    values.splice(index, 1);
    setTue(values);
  };

  //Wed
  const handleWedsChange = (event) => {
    const _tempWeds = [...weds];
    _tempWeds[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempWeds[event.target.dataset.id].start_time >= _tempWeds[event.target.dataset.id].end_time) {
      _tempWeds[event.target.dataset.id].check_validate_time = false;
      setCheckedError(false)
      setWed(_tempWeds);
    } else {
      _tempWeds[event.target.dataset.id].check_validate_time = true;
      setCheckedError(true)
      setWed(_tempWeds);
    }
  };
  const addNewWeds = () => {
    setCheckedError(false)

    setWed((prevWeds) => [...prevWeds, { day_of_week: 3, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveWedsFields = (index) => {
    const values = [...weds];
    values.splice(index, 1);
    setWed(values);
  };

  //Thu
  const handleThusChange = (event) => {
    const _tempThus = [...thus];
    _tempThus[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempThus[event.target.dataset.id].start_time >= _tempThus[event.target.dataset.id].end_time) {
      _tempThus[event.target.dataset.id].check_validate_time = false;
      setCheckedError(false)
      setThu(_tempThus);
    } else {
      _tempThus[event.target.dataset.id].check_validate_time = true;
      setCheckedError(true)
      setThu(_tempThus);
    }
  };
  const addNewThus = () => {
    setCheckedError(false)

    setThu((prevThus) => [...prevThus, { day_of_week: 4, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveThusFields = (index) => {
    const values = [...thus];
    values.splice(index, 1);
    setThu(values);
  };

  //Fri
  const handleFrisChange = (event) => {
    const _tempFris = [...fris];
    _tempFris[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempFris[event.target.dataset.id].start_time >= _tempFris[event.target.dataset.id].end_time) {
      _tempFris[event.target.dataset.id].check_validate_time = false;
      setCheckedError(false)
      setFri(_tempFris);
    } else {
      _tempFris[event.target.dataset.id].check_validate_time = true;
      setCheckedError(true)
      setFri(_tempFris);
    }
  };
  const addNewFris = () => {
    setCheckedError(false)

    setFri((prevFris) => [...prevFris, { day_of_week: 5, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveFrisFields = (index) => {
    const values = [...fris];
    values.splice(index, 1);
    setFri(values);
  };

  //Sat
  const handleSatsChange = (event) => {
    const _tempSats = [...sats];
    _tempSats[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempSats[event.target.dataset.id].start_time >= _tempSats[event.target.dataset.id].end_time) {
      _tempSats[event.target.dataset.id].check_validate_time = false;
      setCheckedError(false)
      setSat(_tempSats);
    } else {
      _tempSats[event.target.dataset.id].check_validate_time = true;
      setCheckedError(true)
      setSat(_tempSats);
    }
  };
  const addNewSats = () => {
    setCheckedError(false)

    setSat((prevSats) => [...prevSats, { day_of_week: 6, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveSatsFields = (index) => {
    const values = [...sats];
    values.splice(index, 1);
    setSat(values);
  };

  //Sun
  const handleSunsChange = (event) => {
    const _tempSuns = [...suns];
    _tempSuns[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempSuns[event.target.dataset.id].start_time >= _tempSuns[event.target.dataset.id].end_time) {
      _tempSuns[event.target.dataset.id].check_validate_time = false;
      setCheckedError(false)
      setSun(_tempSuns);
    } else {
      _tempSuns[event.target.dataset.id].check_validate_time = true;
      setCheckedError(true)
      setSun(_tempSuns);
    }
  };
  const addNewSuns = () => {
    setCheckedError(false)

    setSun((prevSuns) => [...prevSuns, { day_of_week: 7, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveSunsFields = (index) => {
    const values = [...suns];
    values.splice(index, 1);
    setSun(values);
  };


  //MonRe
  const handleMonResChange = (event) => {
    const _tempMonRes = [...monRes];
    _tempMonRes[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempMonRes[event.target.dataset.id].start_time >= _tempMonRes[event.target.dataset.id].end_time) {
      _tempMonRes[event.target.dataset.id].check_validate_time = false;
      setCheckedErrorRe(false)
      setMonRe(_tempMonRes);
    } else {
      _tempMonRes[event.target.dataset.id].check_validate_time = true;
      setCheckedErrorRe(true)
      setMonRe(_tempMonRes);
    }
  };
  const addNewMonRes = () => {
    setCheckedErrorRe(false)
    setMonRe((prevMonRes) => [...prevMonRes, { day_of_week: 1, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveMonResFields = (index) => {
    const values = [...monRes];
    values.splice(index, 1);
    setMonRe(values);
  };

  //TueRe
  const handleTueResChange = (event) => {
    const _tempTueRes = [...tueRes];
    _tempTueRes[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempTueRes[event.target.dataset.id].start_time >= _tempTueRes[event.target.dataset.id].end_time) {
      _tempTueRes[event.target.dataset.id].check_validate_time = false;
      setCheckedErrorRe(false)
      setTueRe(_tempTueRes);
    } else {
      _tempTueRes[event.target.dataset.id].check_validate_time = true;
      setCheckedErrorRe(true)
      setTueRe(_tempTueRes);
    }
  };
  const addNewTueRes = () => {
    setCheckedErrorRe(false)


    setTueRe((prevTueRes) => [...prevTueRes, { day_of_week: 2, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveTueResFields = (index) => {
    const values = [...tueRes];
    values.splice(index, 1);
    setTueRe(values);
  };

  //WedRe
  const handleWedResChange = (event) => {
    const _tempWedRes = [...wedRes];
    _tempWedRes[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempWedRes[event.target.dataset.id].start_time >= _tempWedRes[event.target.dataset.id].end_time) {
      _tempWedRes[event.target.dataset.id].check_validate_time = false;
      setCheckedErrorRe(false)
      setWedRe(_tempWedRes);
    } else {
      _tempWedRes[event.target.dataset.id].check_validate_time = true;
      setCheckedErrorRe(true)
      setWedRe(_tempWedRes);
    }
  };
  const addNewWedRes = () => {
    setCheckedErrorRe(false)
    setWedRe((prevWedRes) => [...prevWedRes, { day_of_week: 3, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveWedResFields = (index) => {
    const values = [...wedRes];
    values.splice(index, 1);
    setWedRe(values);
  };

  //ThuRe
  const handleThuResChange = (event) => {
    const _tempThuRes = [...thuRes];
    _tempThuRes[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempThuRes[event.target.dataset.id].start_time >= _tempThuRes[event.target.dataset.id].end_time) {
      _tempThuRes[event.target.dataset.id].check_validate_time = false;
      setCheckedErrorRe(false)
      setThuRe(_tempThuRes);
    } else {
      _tempThuRes[event.target.dataset.id].check_validate_time = true;
      setCheckedErrorRe(true)
      setThuRe(_tempThuRes);
    }
  };
  const addNewThuRes = () => {
    setCheckedErrorRe(false)
    setThuRe((prevThuRes) => [...prevThuRes, { day_of_week: 4, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveThuResFields = (index) => {
    const values = [...thuRes];
    values.splice(index, 1);
    setThuRe(values);
  };

  //FriRe
  const handleFriResChange = (event) => {
    const _tempFriRes = [...friRes];
    _tempFriRes[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempFriRes[event.target.dataset.id].start_time >= _tempFriRes[event.target.dataset.id].end_time) {
      _tempFriRes[event.target.dataset.id].check_validate_time = false;
      setCheckedErrorRe(false)
      setFriRe(_tempFriRes);
    } else {
      _tempFriRes[event.target.dataset.id].check_validate_time = true;
      setCheckedErrorRe(true)
      setFriRe(_tempFriRes);
    }
  };
  const addNewFriRes = () => {
    setCheckedErrorRe(false)


    setFriRe((prevFriRes) => [...prevFriRes, { day_of_week: 5, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveFriResFields = (index) => {
    const values = [...friRes];
    values.splice(index, 1);
    setFriRe(values);
  };

  //SatRe
  const handleSatResChange = (event) => {
    const _tempSatRes = [...satRes];
    _tempSatRes[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempSatRes[event.target.dataset.id].start_time >= _tempSatRes[event.target.dataset.id].end_time) {
      _tempSatRes[event.target.dataset.id].check_validate_time = false;
      setCheckedErrorRe(false)
      setSatRe(_tempSatRes);
    } else {
      _tempSatRes[event.target.dataset.id].check_validate_time = true;
      setCheckedErrorRe(true)
      setSatRe(_tempSatRes);
    }
  };
  const addNewSatRes = () => {
    setCheckedErrorRe(false)


    setSatRe((prevSatRes) => [...prevSatRes, { day_of_week: 6, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveSatResFields = (index) => {
    const values = [...satRes];
    values.splice(index, 1);
    setSatRe(values);
  };

  //SunRe
  const handleSunResChange = (event) => {
    const _tempSunRes = [...sunRes];
    _tempSunRes[event.target.dataset.id][event.target.name] = event.target.value;
    if (_tempSunRes[event.target.dataset.id].start_time >= _tempSunRes[event.target.dataset.id].end_time) {
      _tempSunRes[event.target.dataset.id].check_validate_time = false;
      setCheckedErrorRe(false)
      setSunRe(_tempSunRes);
    } else {
      _tempSunRes[event.target.dataset.id].check_validate_time = true;
      setCheckedErrorRe(true)
      setSunRe(_tempSunRes);
    }
  };
  const addNewSunRes = () => {
    setCheckedErrorRe(false)
    setSunRe((prevSunRes) => [...prevSunRes, { day_of_week: 7, start_time: "", end_time: "", check_validate_time: false }]);
  };
  const RemoveSunResFields = (index) => {
    const values = [...sunRes];
    values.splice(index, 1);
    setSunRe(values);
  };


  const columns = [
    {
      dataField: "start_date",
      text: "Start Date",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <p>
              {moment(row.start_date).format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "end_date",
      text: "End Date",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <p>
              {moment(row.end_date).format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "period_limit",
      text: "Period Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "yearly_limit",
      text: "Year Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "monthly_limit",
      text: "Month Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "weekly_limit",
      text: "Week Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "daily_limit",
      text: "Day Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: " ",
      headerStyle: (colum, colIndex) => {
        return { width: "80px" };
      },
      formatter: (id, row, index) => {
        return (
          <>
            <button
              className="btn btn-light btn-sm mr-1"
              type="button"
              onClick={() => editCampaignFields({ id, row, index })}
            >
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/General/Edit.svg')} />
            </button>

            <button
              className="btn btn-danger btn-sm"
              type="button"
              onClick={() => RemoveCampaignNewFields({ id, row, index })}
            >
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Home/Trash.svg')} />
            </button>
          </>
        );
      },
    },
  ]
  const confrimCampaign = () => {

    if (newDate >= start_date_campaign) {
      return Swal.fire(
        'Edit !',
        'Start date must more than current date !!',
        'error'
      )
    }
    else if (start_date_campaign >= end_date_campaign) {
      return Swal.fire(
        'Edit !',
        'End date must more than start date !!',
        'error'
      )
    }
    else if (start_date_campaign === undefined || start_date_campaign === "") {
      return Swal.fire(
        'Edit !',
        'Please insert start date for campaign quota !!',
        'error'
      )
    }
    else if (end_date_campaign === undefined || end_date_campaign === "") {
      return Swal.fire(
        'Edit !',
        'Please insert end date for campaign quota !!',
        'error'
      )
    }
    else if (
      period_limit_campaign === undefined &&
      year_limit_campaign === undefined &&
      month_limit_campaign === undefined &&
      week_limit_campaign === undefined &&
      day_limit_campaign === undefined) {
      return Swal.fire(
        'Edit !',
        'Please insert at least one limit for campaign quota !!',
        'error'
      )
    }
    else if (
      period_limit_campaign && period_limit_campaign <= 0) {
      return AlertError('Please insert at period limit more than 0 !!')
    }

    else if (
      year_limit_campaign && year_limit_campaign <= 0) {
      return AlertError('Please insert at year limit more than 0 !!')
    }

    else if (
      month_limit_campaign && month_limit_campaign <= 0) {
      return AlertError('Please insert at month limit more than 0 !!')
    }

    else if (
      week_limit_campaign && week_limit_campaign <= 0) {
      return AlertError('Please insert at week limit more than 0 !!')
    }

    else if (
      day_limit_campaign && day_limit_campaign <= 0) {
      return AlertError('Please insert at day limit more than 0 !!')
    }
    else {
      let data = {
        start_date: moment(start_date_campaign).subtract(7, 'hours').format('YYYY-MM-DD HH:mm'),
        end_date: moment(end_date_campaign).subtract(7, 'hours').format('YYYY-MM-DD HH:mm'),
        period_limit: period_limit_campaign,
        yearly_limit: year_limit_campaign,
        monthly_limit: month_limit_campaign,
        weekly_limit: week_limit_campaign,
        week_start: Number(week_day_limit_campaign),
        week_end: Number(week_day_limit_campaign) === 0 ? 6 : Number(week_day_limit_campaign) - 1,
        daily_limit: day_limit_campaign
      }

      let get = localStorage.getItem("quotaCampaignData")
      const cover_cam = JSON.parse(get);
      cover_cam.map((data_item, key) => {
        if (itemCampaign.index === key) {
          cover_cam[key] = data;
        }
      })

      localStorage.setItem('quotaCampaignData', JSON.stringify(cover_cam));
      setCampaignData(cover_cam);
      setstart_date_campaign(null)
      setend_date_campaign(null)
      setperiod_limit_campaign(undefined)
      setyear_limit_campaign(undefined)
      setmonth_limit_campaign(undefined)
      setweek_limit_campaign(undefined)
      setweek_day_limit_campaign(null)
      setday_limit_campaign(undefined)
      setOpenEditCampaign(false)
      Swal.fire("Edit!", "Your Campaign has been edit.", "success");
    }

  }
  const addCampaingn = () => {
    var data_all = [];
    // setid_q(id_q + 1)

    if (newDate >= start_date_q) {
      return AlertError('Start date must more than current date !!')
    } else if (start_date_q >= end_date_q) {
      return AlertError('End date must more than start date !!')

    } else if (start_date_q === undefined || start_date_q === "") {
      return AlertError('Please insert start date for campaign quota !!')

    } else if (end_date_q === undefined || end_date_q === "") {
      return AlertError('Please insert end date for campaign quota !!')

    }
    else if (
      period_limit_q === undefined &&
      year_limit_q === undefined &&
      month_limit_q === undefined &&
      week_limit_q === undefined &&
      day_limit_q === undefined) {
      return AlertError('Please insert at least one limit for campaign quota !!')

    }

    else if (
      period_limit_q && period_limit_q <= 0) {
      return AlertError('Please insert at period limit more than 0 !!')
    }

    else if (
      year_limit_q && year_limit_q <= 0) {
      return AlertError('Please insert at year limit more than 0 !!')
    }

    else if (
      month_limit_q && month_limit_q <= 0) {
      return AlertError('Please insert at month limit more than 0 !!')
    }

    else if (
      week_limit_q && week_limit_q <= 0) {
      return AlertError('Please insert at week limit more than 0 !!')
    }

    else if (
      day_limit_q && day_limit_q <= 0) {
      return AlertError('Please insert at day limit more than 0 !!')
    } else {
      let data = {
        // id: id_q + 1,
        start_date: moment(start_date_q).subtract(7, 'hours').format('YYYY-MM-DDTHH:mm'),
        end_date: moment(end_date_q).subtract(7, 'hours').format('YYYY-MM-DDTHH:mm'),
        period_limit: period_limit_q,
        yearly_limit: year_limit_q,
        monthly_limit: month_limit_q,
        weekly_limit: week_limit_q,
        week_start: week_limit_q ? Number(week_day) : null,
        week_end: week_limit_q ? Number(week_day) === 0 ? 6 : Number(week_day) - 1 : null,
        daily_limit: day_limit_q
      }
      data_all.push(...campaignData, data)

      setCampaignData(data_all);
      localStorage.setItem("quotaCampaignData", JSON.stringify(data_all))
      setstart_date_q(null)
      setend_date_q(null)
      setperiod_limit_q(undefined)
      setyear_limit_q(undefined)
      setmonth_limit_q(undefined)
      setweek_limit_q(undefined)
      setweek_day(null)
      setday_limit_q(undefined)
    }
  };

  const editCampaignFields = (item) => {
    if (newDate >= item.row.start_date) {
      return Swal.fire(
        'Edit !',
        'Cannot edit because of the date in Campaign.',
        'error'
      )
    } else {
      setOpenEditCampaign(true)
      setItemCampaign(item)
      setstart_date_campaign(moment(item.row.start_date).format("YYYY-MM-DDTHH:mm"))
      setend_date_campaign(moment(item.row.end_date).format("YYYY-MM-DDTHH:mm"))
      setperiod_limit_campaign(item.row.period_limit)
      setyear_limit_campaign(item.row.yearly_limit)
      setmonth_limit_campaign(item.row.monthly_limit)
      setweek_limit_campaign(item.row.weekly_limit)
      setweek_day_limit_campaign(item.row.week_start)
      setday_limit_campaign(item.row.daily_limit)
    }
  }
  const handleCloseCampaign = () => {
    setOpenEditCampaign(false);
    setstart_date_campaign(null)
    setend_date_campaign(null)
    setperiod_limit_campaign(undefined)
    setyear_limit_campaign(undefined)
    setmonth_limit_campaign(undefined)
    setweek_limit_campaign(undefined)
    setweek_day_limit_campaign(null)
    setday_limit_campaign(undefined)
  }
  const handleChangeType = (name) => {
    setType(name);
  };

  const RemoveCampaignNewFields = (items) => {
    if (newDate >= items.row.start_date) {
      return Swal.fire(
        'Delete !',
        'Cannot delete because of the date in Campaign.',
        'error'
      )
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this Campaign !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.value) {
          let get = localStorage.getItem("quotaCampaignData")
          const cover_cam = JSON.parse(get)
          cover_cam.map((data, key) => {
            if (items.index === key) {
              cover_cam.splice(items.index, 1);
            }
          })
          localStorage.setItem("quotaCampaignData", JSON.stringify(cover_cam))
          setCampaignData(cover_cam);
          Swal.fire("Deleted!", "Your Campaign has been deleted.", "success");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your Campaign is safe ", "error");
        }
      });
    }
  };


  const columns_m = [
    {
      dataField: "start_date",
      text: "Start Date",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <p>
              {moment(row.start_date).format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "end_date",
      text: "End Date",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      formatter: (id, row, rowIndex) => {
        return (
          <div>
            <p>
              {moment(row.end_date).format('YYYY-MM-DD HH:mm:ss')}
            </p>
          </div>
        );
      },
    },
    {
      dataField: "period_limit",
      text: "Period Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "yearly_limit",
      text: "Year Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "monthly_limit",
      text: "Month Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "weekly_limit",
      text: "Week Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "daily_limit",
      text: "Day Limit",
      headerStyle: (colum, colIndex) => {
        return { width: "100px" };
      },
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      text: " ",
      headerStyle: (colum, colIndex) => {
        return { width: "80px" };
      },
      formatter: (id, row, index) => {
        return (
          <>
            <button
              className="btn btn-light btn-sm mr-1"
              type="button"
              onClick={() => editMemberFields({ id, row, index })}
            >
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/General/Edit.svg')} />
            </button>

            <button
              className="btn btn-danger btn-sm"
              type="button"
              onClick={() => RemoveMemberNewFields({ id, row, index })}
            >
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Home/Trash.svg')} />
            </button>
          </>
        );
      },
    },
  ]

  const confrimMember = () => {

    if (newDate >= start_date_member) {
      return Swal.fire(
        'Edit !',
        'Start date must more than current date !!',
        'error'
      )
    }
    else if (start_date_member >= end_date_member) {
      return Swal.fire(
        'Edit !',
        'End date must more than start date !!',
        'error'
      )
    }
    else if (start_date_member === undefined || start_date_member === "") {
      return Swal.fire(
        'Edit !',
        'Please insert start date for member quota !!',
        'error'
      )
    }
    else if (end_date_member === undefined || end_date_member === "") {
      return Swal.fire(
        'Edit !',
        'Please insert end date for member quota !!',
        'error'
      )
    }
    else if (
      period_limit_member === undefined &&
      year_limit_member === undefined &&
      month_limit_member === undefined &&
      week_limit_member === undefined &&
      day_limit_member === undefined) {
      return Swal.fire(
        'Edit !',
        'Please insert at least one limit for member quota !!',
        'error'
      )
    }

    else if (
      period_limit_member && period_limit_member <= 0) {
      return AlertError('Please insert at period limit more than 0 !!')
    }

    else if (
      year_limit_member && year_limit_member <= 0) {
      return AlertError('Please insert at year limit more than 0 !!')
    }

    else if (
      month_limit_member && month_limit_member <= 0) {
      return AlertError('Please insert at month limit more than 0 !!')
    }

    else if (
      week_limit_member && week_limit_member <= 0) {
      return AlertError('Please insert at week limit more than 0 !!')
    }

    else if (
      day_limit_member && day_limit_member <= 0) {
      return AlertError('Please insert at day limit more than 0 !!')
    }
    else {
      let data = {
        start_date: moment(start_date_member).subtract(7, 'hours').format('YYYY-MM-DD HH:mm'),
        end_date: moment(end_date_member).subtract(7, 'hours').format('YYYY-MM-DD HH:mm'),
        period_limit: period_limit_member,
        yearly_limit: year_limit_member,
        monthly_limit: month_limit_member,
        weekly_limit: week_limit_member,
        week_start: week_limit_member ? Number(week_day_limit_member) : null,
        week_end: week_limit_member ? Number(week_day_limit_member) === 0 ? 6 : Number(week_day_limit_member) - 1 : null,
        daily_limit: day_limit_member
      }

      let get = localStorage.getItem("quotaMemberData")
      const cover_cam = JSON.parse(get);
      cover_cam.map((data_item, key) => {
        if (itemMember.index === key) {
          cover_cam[key] = data;
        }
      })

      localStorage.setItem('quotaMemberData', JSON.stringify(cover_cam));
      setMemberData(cover_cam);
      setstart_date_member(null)
      setend_date_member(null)
      setperiod_limit_member(undefined)
      setyear_limit_member(undefined)
      setmonth_limit_member(undefined)
      setweek_limit_member(undefined)
      setweek_day_limit_member(null)
      setday_limit_member(undefined)
      setOpenEditMember(false)
      Swal.fire("Edit!", "Your member has been edit.", "success");
    }

  }

  const addMember = () => {
    var data_all = [];
    // setid_qm(id_q + 1)

    if (newDate >= start_date_qm) {
      return AlertError('Start date must more than current date !!')
    }
    else if (start_date_qm > end_date_qm) {
      return AlertError('Start date quota member must then end date !!')

    } else if (start_date_qm === undefined || start_date_qm === "") {
      return AlertError('Please insert start date quota member !!')

    } else if (end_date_qm === undefined || end_date_qm === "") {
      return AlertError('Please insert end date quota member !!')

    }
    else if (
      period_limit_qm === undefined &&
      year_limit_qm === undefined &&
      month_limit_qm === undefined &&
      week_limit_qm === undefined &&
      day_limit_qm === undefined) {
      return AlertError('Please insert at least one limit for member quota !!')

    }
    else if (
      period_limit_qm && period_limit_qm <= 0) {
      return AlertError('Please insert at period limit more than 0 !!')
    }

    else if (
      year_limit_qm && year_limit_qm <= 0) {
      return AlertError('Please insert at year limit more than 0 !!')
    }

    else if (
      month_limit_qm && month_limit_qm <= 0) {
      return AlertError('Please insert at month limit more than 0 !!')
    }

    else if (
      week_limit_qm && week_limit_qm <= 0) {
      return AlertError('Please insert at week limit more than 0 !!')
    }

    else if (
      day_limit_qm && day_limit_qm <= 0) {
      return AlertError('Please insert at day limit more than 0 !!')
    } else {
      let data = {
        // id: id_qm + 1,
        start_date: moment(start_date_qm).subtract(7, 'hours').format('YYYY-MM-DDTHH:mm'),
        end_date: moment(end_date_qm).subtract(7, 'hours').format('YYYY-MM-DDTHH:mm'),
        period_limit: period_limit_qm,
        yearly_limit: year_limit_qm,
        monthly_limit: month_limit_qm,
        weekly_limit: week_limit_qm,
        week_start: week_limit_member ? Number(week_day_qm) : null,
        week_end: week_limit_member ? Number(week_day_qm) === 0 ? 6 : Number(week_day_qm) - 1 : null,
        daily_limit: day_limit_qm
      }
      data_all.push(...memberData, data)
      setMemberData(data_all);
      localStorage.setItem("quotaMemberData", JSON.stringify(data_all))
      setstart_date_qm(null)
      setend_date_qm(null)
      setperiod_limit_qm(undefined)
      setyear_limit_qm(undefined)
      setmonth_limit_qm(undefined)
      setweek_limit_qm(undefined)
      setweek_day_qm(null)
      setday_limit_qm(undefined)
    }
  };

  const handleCloseMember = () => {
    setOpenEditMember(false);
    setstart_date_member(null)
    setend_date_member(null)
    setperiod_limit_member(undefined)
    setyear_limit_member(undefined)
    setmonth_limit_member(undefined)
    setweek_limit_member(undefined)
    setweek_day_limit_member(null)
    setday_limit_member(undefined)
  }
  const editMemberFields = (item) => {
    if (newDate >= item.row.start_date) {
      return Swal.fire(
        'Edit !',
        'Cannot edit because of the date in Member.',
        'error'
      )
    } else {
      setOpenEditMember(true)
      setItemMember(item)
      setstart_date_member(moment(item.row.start_date).format("YYYY-MM-DDTHH:mm"))
      setend_date_member(moment(item.row.end_date).format("YYYY-MM-DDTHH:mm"))
      setperiod_limit_member(item.row.period_limit)
      setyear_limit_member(item.row.yearly_limit)
      setmonth_limit_member(item.row.monthly_limit)
      setweek_limit_member(item.row.weekly_limit)
      setweek_day_limit_member(item.row.week_start)
      setday_limit_member(item.row.daily_limit)
    }
  }

  const RemoveMemberNewFields = (items) => {
    if (newDate >= items.row.start_date) {
      return Swal.fire(
        'Delete !',
        'Cannot delete because of the date in member.',
        'error'
      )
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to recover this member !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, keep it",
      }).then((result) => {
        if (result.value) {
          let get = localStorage.getItem("quotaMemberData")
          const cover_cam = JSON.parse(get)
          cover_cam.map((data, key) => {
            if (items.index === key) {
              cover_cam.splice(items.index, 1);
            }
          })
          localStorage.setItem("quotaMemberData", JSON.stringify(cover_cam))
          setMemberData(cover_cam);
          Swal.fire("Deleted!", "Your member has been deleted.", "success");
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire("Cancelled", "Your member is safe ", "error");
        }
      });
    }
  };

  const handleChangeSwitch = (name) => (event) => {
    setState({ ...state, [name]: event.target.checked });
  };

  const handleChangeFile = async (e) => {
    setIsUpload(true);
    if (e.target.files.length) {
      e.preventDefault();
      let file = e.target.files[0];
      setFile(file);
      setNameFile(file.name);
    }
  };

  const getSteps = () => {
    return ['Step 1', 'Step 2', 'Step 3'];
  }
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  const handleNext = () => {
    const dealTags = []
    tagItem.map((tage_data, key) => {
      dealTags.push(tage_data.tag_id)
    })
    if (activeStep === 2) {

      if (type === "auto") {
        if (length === undefined || length === '' || isNaN(length) || Number(length) === 0 || Number(length) < 0) {
          return AlertError('please insert length.')
        }
        if (amount === undefined || amount === '' || isNaN(amount) || Number(amount) === 0 || Number(amount) < 0) {
          return AlertError('please insert amount.')
        }

        if (
          symbols === false &&
          uppercase === false &&
          numbers === false &&
          lowercase === false) {
          return AlertError('Please select case !!')
        }

        var events_tree = {
          deal_id: id_res,
          config_length: length,
          amount: amount,
          config_symbols: symbols,
          config_uppercase: uppercase,
          config_numbers: numbers,
          config_lowercase: lowercase,
        }

        axios.post(`${privilegeURLV2}/deal-code/generate`, events_tree, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessTokenPrivilege}`,
          }
        })
          .then((res_tree) => {
            Swal.fire("Save !", "Save success", "success");
            history.goBack();
          })
          .catch((err) => {
            Swal.fire(
              'Created !',
              'Your can not create step 3. !! ' + err,
              'error'
            )
          })

      } else {

        if (!check_upload) {
          return AlertError('Please upload file !!')
        }

      }

    } else if (activeStep === 1) {
      let getCampaign = localStorage.getItem("quotaCampaignData")
      let getMember = localStorage.getItem("quotaMemberData")

      const cover_Campaign = getCampaign ? JSON.parse(getCampaign) : []
      const cover_Member = getMember ? JSON.parse(getMember) : []

      const display_dialog_datetime = []
      if (checkedMon) {
        mons.map((data, key) => {
          display_dialog_datetime.push(data)
        })
      }

      if (checkedTue) {
        tues.map((data, key) => {
          display_dialog_datetime.push(data)
        })
      }

      if (checkedWed) {
        weds.map((data, key) => {
          display_dialog_datetime.push(data)
        })
      }

      if (checkedThu) {
        thus.map((data, key) => {
          display_dialog_datetime.push(data)
        })
      }

      if (checkedThu) {
        thus.map((data, key) => {
          display_dialog_datetime.push(data)
        })
      }

      if (checkedFri) {
        fris.map((data, key) => {
          display_dialog_datetime.push(data)
        })
      }

      if (checkedSat) {
        sats.map((data, key) => {
          display_dialog_datetime.push(data)
        })
      }
      if (checkedSun) {
        suns.map((data, key) => {
          display_dialog_datetime.push(data)
        })
      }

      const redeem_dialog_datetime = []
      if (checkedMonRe) {
        monRes.map((data, key) => {
          redeem_dialog_datetime.push(data)
        })
      }

      if (checkedTueRe) {
        tueRes.map((data, key) => {
          redeem_dialog_datetime.push(data)
        })
      }

      if (checkedWedRe) {
        wedRes.map((data, key) => {
          redeem_dialog_datetime.push(data)
        })
      }

      if (checkedThuRe) {
        thuRes.map((data, key) => {
          redeem_dialog_datetime.push(data)
        })
      }

      if (checkedFriRe) {
        friRes.map((data, key) => {
          redeem_dialog_datetime.push(data)
        })
      }

      if (checkedSatRe) {
        satRes.map((data, key) => {
          redeem_dialog_datetime.push(data)
        })
      }
      if (checkedSunRe) {
        sunRes.map((data, key) => {
          redeem_dialog_datetime.push(data)
        })
      }

      var events_two = {
        deal_id: id_res,
        display: [
          {
            start_date: moment(startDateDisplay).subtract(7, 'hours').format('YYYY-MM-DDTHH:mm'),
            end_date: moment(endDateDisplay).subtract(7, 'hours').format('YYYY-MM-DDTHH:mm'),
            dialog_datetime: display_dialog_datetime,
          }
        ],
        redeem: [
          {
            start_date: startDateRedeem ? moment(startDateRedeem).subtract(7, 'hours').format('YYYY-MM-DDTHH:mm') : moment(startDateDisplay).subtract(7, 'hours').format('YYYY-MM-DDTHH:mm'),
            end_date: endDateRedeem ? moment(endDateRedeem).subtract(7, 'hours').format('YYYY-MM-DDTHH:mm') : moment(startDateDisplay).subtract(7, 'hours').format('YYYY-MM-DDTHH:mm'),
            dialog_datetime: redeem_dialog_datetime,
          }
        ],
        quota_campaign: cover_Campaign,
        quota_member: cover_Member
      };
      console.log(events_two);

      if (startDateDisplay === undefined || startDateDisplay === "") {
        return AlertError('Please insert start date for privilege display !!')

      }
      if (endDateDisplay === undefined || endDateDisplay === "") {
        return AlertError('Please insert end date for privilege display !!')

      }
      if (startDateDisplay >= endDateDisplay) {
        return AlertError('End date must more than start date !!')

      }
      // if (startDateRedeem === undefined || startDateRedeem === "") {
      //   return AlertError('Please insert start date for privilege redeem !!')

      // }
      if (endDateRedeem === undefined || endDateRedeem === "") {
        return AlertError('Please insert end date for privilege redeem !!')

      }
      if (startDateRedeem >= endDateRedeem) {
        return AlertError('End date must more than start date !!')

      }

      if (cover_Campaign.length === 0) {
        return AlertError('Please add campaign quota !!')

      }

      if (id_res_two) {
        axios.put(`${privilegeURLV2}/deal-rule${id_res_two}`, events_two, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessTokenPrivilege}`,
          }
        })
          .then((res_two) => {
            setActiveStep(activeStep + 1)
          })
          .catch((err) => {
            Swal.fire(
              'Edit !',
              'Your can not create step 2. !! ' + err,
              'error'
            )
          })
      } else {
        axios.post(`${privilegeURLV2}/deal-rule`, events_two, {
          headers: {
            Accept: "application/json",
            Authorization: `Bearer ${accessTokenPrivilege}`,
          }
        })
          .then((res_two) => {
            setid_res_two(res_two.data.data.id)
            setActiveStep(activeStep + 1)
          })
          .catch((err) => {
            Swal.fire(
              'Created !',
              'Your can not create step 2. !! ' + err,
              'error'
            )
          })
      }
    } else if (activeStep === 0) {
      var events_one = {
        campaign_id: campaigns_id_headder,
        deal_name: name,
        deal_tags: dealTags,
        deal_detail_short: detail,
        deal_detail_condition: deal_detail_condition,
        deal_image: item_pic_path,
        point: point,
        deal_display_weight: display_weight,
        //cost: costs,
        step_deal: step_deal,
        deal_logo: item_pic_path2,
        deal_detail_location: location,
        is_delivery: false,
        is_lucky: false,
        allow_once: state.allow_once ? state.allow_once : false,
        deal_active: state.is_active ? state.is_active : false,
        deal_expire_min: expire
        //is_show_on_front: state.is_show_on_front ? state.is_show_on_front : false,
      };
      if (name === undefined || name === "") {
        return AlertError('Please insert name!!')
      } else if (dealTags.length === 0) {
        return AlertError('Please select tag !!')
      } else if (item_pic_path === undefined || item_pic_path === "") {
        return AlertError('Please select image !!')
      } else if (point === undefined || point === "") {
        return AlertError('Please insert point !!')
      }
      else if (
        point && point < 0) {
        return AlertError('Please insert at point more than 0 !!')
      }
      else {
        if (id_res) {
          axios.put(`${privilegeURLV2}/deal/${id_res}`, events_one, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${accessTokenPrivilege}`,
            }
          })
            .then((res_one) => {
              setActiveStep(activeStep + 1)
            })
            .catch((err) => {
              Swal.fire(
                'Edit !',
                'Your can not create step 1. !! ' + err,
                'error'
              )
            })
        } else {
          axios.post(`${privilegeURLV2}/deal`, events_one, {
            headers: {
              Accept: "application/json",
              Authorization: `Bearer ${accessTokenPrivilege}`,
            }
          })
            .then((res_one) => {
              setid_res(res_one.data.data.id)
              setActiveStep(activeStep + 1)
            })
            .catch((err) => {
              Swal.fire(
                'Created !',
                'Your can not create step 1. !! ' + err,
                'error'
              )
            })
        }
      }
    }
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => {
      if (activeStep === 0) {
        history.goBack();
      } else {
        setActiveStep(activeStep - 1)
      }
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const imageResize = new ImageResize({
    format: "png",
    quality: 0.5,
  });

  const handleChangeItem_pic_path = async e => {
    if (e.target.files.length) {
      e.preventDefault()
      var _URL = window.URL || window.webkitURL;
      var file = e.target.files[0]
      var img = new Image();
      var width = 0;
      img.src = _URL.createObjectURL(file);
      img.onload = function () {
        var imageResize = new ImageResize({
          format: "png",
          width: this.width > 1000 ? 1000 : this.width,
        }).play(URL.createObjectURL(file))
          .then(function (response) {
            var i = new Image();
            i.onload = function (even) {
              setStateItem_pic_path({
                rawItem_pic_path: response,
                picURLItem_pic_path: even.target.result
              });
            };
            i.src = response;
            var cover = dataURLtoFile(response, file.name);
            handlepicUploadItem_pic_path(cover)
          })
          .catch(function (error) {
            console.error(error);
          });
      };
    }
  }
  const handlepicUploadItem_pic_path = async file => {
    const privilegeToken = localStorage.getItem('accessTokenPrivilege')
    var formdata = new FormData()
    formdata.append('image', file, file.name)
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      headers: {
        "Authorization": `Bearer ${privilegeToken}`
      }
    }
    fetch(`${privilegeURL}/upload/`, requestOptions)
      .then(response => response.text())
      .then(result => {
        let res = JSON.parse(result)
        setStateItem_pic_path({
          previewItem_pic_path: null,
          rawItem_pic_path: null,
          picURLItem_pic_path: null
        })
        setItem_pic_path(`${res.filename}`)
        console.log(`${privilegeImage}/${res.path}`)

        setNamepicItem_pic_path(`${res.url}`)
      })
      .catch(error => console.log('error', error))
  }

  const removepicItem_pic_path = async () => {
    setStateItem_pic_path({
      previewItem_pic_path: null,
      rawItem_pic_path: null,
      picURLItem_pic_path: null
    })
    setItem_pic_path('')
  }

  const handleChangeItem_pic_path2 = async e => {
    if (e.target.files.length) {
      e.preventDefault()
      var _URL = window.URL || window.webkitURL;
      var file = e.target.files[0]
      var img = new Image();
      var width = 0;
      img.src = _URL.createObjectURL(file);
      img.onload = function () {
        var imageResize = new ImageResize({
          format: "png",
          width: this.width > 1000 ? 1000 : this.width,
        }).play(URL.createObjectURL(file))
          .then(function (response) {
            var i = new Image();
            i.onload = function (even) {
              setStateItem_pic_path2({
                rawItem_pic_path: response,
                picURLItem_pic_path: even.target.result
              });
            };
            i.src = response;
            var cover = dataURLtoFile(response, file.name);
            handlepicUploadItem_pic_path2(cover)
          })
          .catch(function (error) {
            console.error(error);
          });
      };
    }
  }
  const handlepicUploadItem_pic_path2 = async file => {
    const privilegeToken = localStorage.getItem('accessTokenPrivilege')
    var formdata = new FormData()
    formdata.append('image', file, file.name)
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      headers: {
        "Authorization": `Bearer ${privilegeToken}`
      }
    }
    fetch(`${privilegeURL}/upload/`, requestOptions)
      .then(response => response.text())
      .then(result => {
        let res = JSON.parse(result)
        setStateItem_pic_path2({
          previewItem_pic_path: null,
          rawItem_pic_path: null,
          picURLItem_pic_path: null
        })
        console.log(`${privilegeImage}/${res.path}`)

        setItem_pic_path2(`${res.filename}`)
        setNamepicItem_pic_path2(`${res.url}`)
      })
      .catch(error => console.log('error', error))
  }

  const removepicItem_pic_path2 = async () => {
    setStateItem_pic_path2({
      previewItem_pic_path: null,
      rawItem_pic_path: null,
      picURLItem_pic_path: null
    })
    setItem_pic_path2('')
  }

  const handleCancelUploadItem_pic_path = () => {
    setStateItem_pic_path({
      previewItem_pic_path: null,
      rawItem_pic_path: null,
      picURLItem_pic_path: null
    })
  }


  const handleChangeItem_logo_path = async e => {
    if (e.target.files.length) {
      e.preventDefault()
      var _URL = window.URL || window.webkitURL;
      var file = e.target.files[0]
      var img = new Image();
      var width = 0;
      img.src = _URL.createObjectURL(file);
      img.onload = function () {
        var imageResize = new ImageResize({
          format: "png",
          width: this.width > 1000 ? 1000 : this.width,
        }).play(URL.createObjectURL(file))
          .then(function (response) {
            var i = new Image();
            i.onload = function (even) {
              setStateItem_logo_path({
                rawItem_pic_path: response,
                logoURLItem_logo_path: even.target.result
              });
            };
            i.src = response;
            var cover = dataURLtoFile(response, file.name);
            handlelogoUploadItem_logo_path(cover)
          })
          .catch(function (error) {
            console.error(error);
          });
      };
    }
  }
  const handlelogoUploadItem_logo_path = async file => {
    const privilegeToken = localStorage.getItem('accessTokenPrivilege')
    var formdata = new FormData()
    formdata.append('image', file)
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow',
      headers: {
        "Authorization": `Bearer ${privilegeToken}`
      }
    }
    fetch(`${privilegeURL}/upload/`, requestOptions)
      .then(response => response.text())
      .then(result => {
        let res = JSON.parse(result)
        setStateItem_logo_path({
          previewItem_logo_path: null,
          rawItem_logo_path: null,
          logoURLItem_logo_path: null
        })
        setItem_logo_path(`${res.filename}`)
        setNamelogoItem_logo_path(`${res.url}`)
      })
      .catch(error => console.log('error', error))
  }

  const removelogoItem_logo_path = async () => {
    setStateItem_logo_path({
      previewItem_logo_path: null,
      rawItem_logo_path: null,
      logoURLItem_logo_path: null
    })
    setItem_logo_path('')
  }

  const handleCancelUploadItem_logo_path = () => {
    setStateItem_logo_path({
      previewItem_logo_path: null,
      rawItem_logo_path: null,
      logoURLItem_logo_path: null
    })
  }


  const save = async () => {
    setProgress(25);
    const formData = new FormData();
    // Update the formData object
    formData.append("deal_id", id_res);
    formData.append("file", file, file.name);

    axios({
      method: "post",
      url: `${privilegeURLV2}/deal-code/upload/coupon`,
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${user.data.access_token}`,
      },
      data: formData,
    })
      .then(async (res) => {
        await setProgress(100);
        await setCheck_upload(true)
        await setIsUpload(false)
        await setProgress(0);
        await Swal.fire("Save !", "Upload success", "success");
        await history.goBack();
      })
      .catch((err) => {
        Swal.fire("Error", "Your can't upload file.", "error");
        setCheck_upload(false)
        setIsUpload(false)
        setProgress(0);
      });
  };

  const deleteUpload = async () => {
    setIsUpload(false);
    setFile(null);
    setNameFile("");
  };
  const confrim = async () => {
    setLoding(true)
    var data_all = []
    if (checkedMon) {
      await mons.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 1, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }
    if (checkedTue) {
      await tues.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 2, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }
    if (checkedWed) {
      await weds.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 3, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }
    if (checkedThu) {
      await thus.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 4, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }
    if (checkedFri) {
      await fris.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 5, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }
    if (checkedSat) {
      await sats.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 6, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }
    if (checkedSun) {
      await suns.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 7, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }
    if (data_all.length > 0) {
      await setDialogTable(data_all)
      await setOpenDialogModalTable(false);
      await setLoding(false)

    } else {
      setLoding(false)
      return Swal.fire(
        'Edit !',
        'Please check time !!',
        'error'
      )
    }

  }
  const handleClose = () => {
    setOpenDialogModalTable(false);

  }

  const confrimRe = async () => {
    setLoding(true)
    var data_all = []
    if (checkedMonRe) {
      await monRes.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 1, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }
    if (checkedTueRe) {
      await tueRes.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 2, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }
    if (checkedWedRe) {
      await wedRes.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 3, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }
    if (checkedThuRe) {
      await thuRes.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 4, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }
    if (checkedFriRe) {
      await friRes.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 5, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }
    if (checkedSatRe) {
      await satRes.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 6, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }
    if (checkedSunRe) {
      await sunRes.map((data, index) => {
        if (data.check_validate_time) {
          data_all.push({ day_of_week: 7, start_time: data.start_time, end_time: data.end_time })
        } else {
          data_all = []
        }
      })
    }

    if (data_all.length > 0) {
      setDialogTableRe(data_all)
      setOpenDialogModalReTable(false);
    } else {
      setLoding(false)
      return Swal.fire(
        'Edit !',
        'Please check time !!',
        'error'
      )
    }
  }

  const handleCloseRe = () => {
    setOpenDialogModalReTable(false);
  }

  const openPeriod = async () => {
    setOpenDialogModalTable(true)
  }

  const openPeriodRe = async () => {
    setOpenDialogModalReTable(true)
  }

  const StepOne = () => {
    return (
      <div>
        <Card>
          <CardHeader title="Step 1"></CardHeader>
          <CardBody>
            <div className="container">
              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Name:{" "}
                </span>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={name}
                  onChange={(name) => setName(name.target.value)}
                />
              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Short Detail:{" "}
                </span>

                <div>
                  <JoditEditor
                    messageChange={(detail) => setDetail(detail)}
                    messageValue={detail} />
                </div>

                {/* <textarea
                  className="form-control"
                  id="exampleFormControlTextarea1"
                  rows="3"
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={detail}
                  onChange={(detail) =>
                    setDetail(detail.target.value)
                  }
                /> */}
              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Category:{" "}
                </span>
                <Autocomplete
                  multiple
                  id="grouped-demo"
                  options={tagData}
                  groupBy={(option) => option.topic_name}
                  getOptionLabel={(option) => option.tag_and_data}
                  getOptionSelected={(option, value) => option.tag_id === value.tag_id}
                  value={tagItem}
                  onChange={(event, newValue) => {
                    setTagItem(newValue);
                  }}
                  style={{
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  renderInput={(params) =>
                    <TextField {...params} variant="outlined" />
                  }
                />

              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}>
                  Condition:{" "}
                </p>
                <JoditEditor messageChange={handleConditionChange} messageValue={deal_detail_condition} />

                {/* <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={deal_detail_condition}
                  onChange={(deal_detail_condition) => setdeal_detail_condition(deal_detail_condition.target.value)}
                /> */}
              </div>

              <div className="form-inline" style={{ marginBottom: '25px' }}>
                <span style={{ marginTop: '15px', float: 'left', width: '20%' }}>
                  Image : <br></br>
                </span>
                <input
                  name="item_pic_path"
                  style={{
                    height: '38px',
                    width: '330px',
                    backgroundColor: '#F4F6F9',
                    borderRadius: '5px',
                    borderWidth: '0'
                  }}
                  disabled
                  value={item_pic_path}
                />

                <span className="btn btn-primary btn-file wbt">
                  เลือก / แก้ไข
                  <input type="file" onChange={handleChangeItem_pic_path} />
                </span>

                <button
                  type="button"
                  className="btn btn-danger wbt"
                  onClick={removepicItem_pic_path}
                >
                  ลบ
                </button>
              </div>

              <div
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '50%'
                }}
              >
                {stateItem_pic_path.previewItem_pic_path ? (
                  <img
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                    src={stateItem_pic_path.previewItem_pic_path}
                  />
                ) : item_pic_path ? (
                  <img
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                    src={namepicItem_pic_path}
                  />
                ) : null}
              </div>

              <br></br>

              <div className="form-inline" style={{ marginBottom: '25px' }}>
                <span style={{ marginTop: '15px', float: 'left', width: '20%' }}>
                  logo : <br></br>
                </span>
                <input
                  name="item_pic_path"
                  style={{
                    height: '38px',
                    width: '330px',
                    backgroundColor: '#F4F6F9',
                    borderRadius: '5px',
                    borderWidth: '0'
                  }}
                  disabled
                  value={item_pic_path2}
                />

                <span className="btn btn-primary btn-file wbt">
                  เลือก / แก้ไข
                  <input type="file" onChange={handleChangeItem_pic_path2} />
                </span>

                <button
                  type="button"
                  className="btn btn-danger wbt"
                  onClick={removepicItem_pic_path2}
                >
                  ลบ
                </button>
              </div>

              <div
                style={{
                  display: 'block',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  width: '50%'
                }}
              >
                {stateItem_pic_path2.previewItem_pic_path ? (
                  <img
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                    src={stateItem_pic_path2.previewItem_pic_path}
                  />
                ) : item_pic_path2 ? (
                  <img
                    style={{
                      width: '100%',
                      height: '100%'
                    }}
                    src={namepicItem_pic_path2}
                  />
                ) : null}
              </div>

              <br></br>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Point:{" "}
                </span>
                <input
                  type="number"
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={point}
                  min="0"
                  // onChange={(point) => setPoint(point.target.value)}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (value >= 0) {
                      setPoint(value); // ✅ รับเฉพาะค่าที่มากกว่าหรือเท่ากับ 0
                    }
                  }}
                />
              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Display Order:{" "}
                </span>
                <input
                  type="number"
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={display_weight}
                  onChange={(weight) => setDisplayWeight(weight.target.value)}
                />
              </div>

              {/*<div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Cost:{" "}
                </span>
                <input
                  type="number"
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={costs}
                  onChange={(costs) => setCosts(costs.target.value)}
                />
              </div>*/}

              {/*<div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Location:{" "}
                </span>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={location}
                  onChange={(name) => setLocation(name.target.value)}
                />
              </div>*/}

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Location:{" "}
                </span>

                <div>
                  {detail !== undefined ?
                    <JoditEditor
                      messageChange={(detail) => setLocation(detail)}
                      messageValue={location} />
                    : ''}
                </div>
              </div>

              {/*<div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Delivery :{" "}
                </p>
                <Switch
                  disabled={state.is_lucky}
                  checked={state.is_delivery}
                  onChange={handleChangeSwitch("is_delivery")}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>*/}

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <span
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Deal Expire (minnute):{" "}
                </span>
                <input
                  style={{
                    height: "38px",
                    width: "530px",
                    backgroundColor: "#F4F6F9",
                    borderRadius: "5px",
                    borderWidth: "0",
                  }}
                  value={expire}
                  onChange={(name) => setExpire(name.target.value)}
                />
              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  redeem points :{" "}
                </p>
                <Switch
                  checked={step_deal}
                  onChange={event => setStep_deal(event.target.checked)}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Active :{" "}
                </p>
                <Switch
                  checked={state.is_active}
                  onChange={handleChangeSwitch("is_active")}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>

              {/*<div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Lucky :{" "}
                </p>
                <Switch
                  disabled={state.is_delivery}
                  checked={state.is_lucky}
                  onChange={handleChangeSwitch("is_lucky")}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>*/}

              {/*<div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >
                  Allow Once (History):{" "}
                </p>
                <Switch
                  checked={state.allow_once}
                  onChange={handleChangeSwitch("allow_once")}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>*/}

              <div
                className="form-inline"
                style={{
                  marginBottom: "25px",
                }}
              >
                <p
                  style={{
                    marginTop: "15px",
                    float: "left",
                    width: "20%",
                  }}
                >

                  Show on front :{" "}
                </p>
                <Switch
                  checked={state.is_show_on_front}
                  onChange={handleChangeSwitch("is_show_on_front")}
                  inputProps={{ "aria-label": "secondary checkbox" }}
                />
              </div>

            </div>
          </CardBody>
        </Card>
      </div>
    )
  }
  function getStepContent(stepIndex) {
    switch (stepIndex) {
      case 0:
        return StepOne();
      case 1:
        return StepTwo();
      case 2:
        return StepTree();
      default:
        return 'Unknown stepIndex';
    }
  }

  const StepTwo = () => {
    return (
      <div>
        <Card>
          <CardHeader title="Step 2"></CardHeader>
          <CardBody>
            <div className={classes.root}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Display</Typography>
                </AccordionSummary>

                <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    Start Date:{" "}
                  </p>
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      // onClose
                      variant="dialog"
                      ampm={false}
                      value={startDateDisplay}
                      onChange={(data) => {
                        setStartDateDisplay(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                        setEndDateDisplay(moment(data._d).add(1, 'hours').format("YYYY-MM-DDTHH:mm"));
                        setStartDateRedeem(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                        setEndDateRedeem(moment(data._d).add(1, 'hours').format("YYYY-MM-DDTHH:mm"));
                      }}
                      // disablePast
                      format="DD/MM/YYYY HH:mm"
                    // showTodayButton
                    />
                  </MuiPickersUtilsProvider>*/}
                  {/*<TextField
                    id="datetime-locals"
                    type="datetime-local"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={startDateDisplay}
                    onChange={(data) => {
                      setStartDateDisplay(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                      /*setEndDateDisplay(moment(data._d).add(1, 'hours').format("YYYY-MM-DDTHH:mm"));
                      setStartDateRedeem(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                      setEndDateRedeem(moment(data._d).add(1, 'hours').format("YYYY-MM-DDTHH:mm"));
                    }
                    }
                  />*/}
                  <div className="form-inline" style={{ marginBottom: "25px" }}>
                    <input id="datestart" type="datetime-local" value={startDateDisplay} defaultValue="DD/MM/YYYYTHH:mm" style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(data) => {
                        console.log(data.target.value)
                        setStartDateDisplay(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))
                        setstart_date_q(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))
                        setstart_date_qm(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))
                        if (!endDateDisplay) {
                          setEndDateDisplay(moment(data.target.value).format("YYYY-MM-DDTHH:mm"));
                        }

                        setStartDateRedeem(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))

                        /*if (!endDateRedeem) {
                          setEndDateRedeem(moment(data.target.value).format("YYYY-MM-DDTHH:mm"));
                        }*/

                      }

                      }
                    />
                  </div>
                </div>

                <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    End Date:{" "}
                  </p>
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      // onClose
                      variant="dialog"
                      minDate={startDateDisplay}
                      disabled={!startDateDisplay}
                      ampm={false}
                      value={endDateDisplay}
                      onChange={(data) => {
                        setEndDateDisplay(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                        setEndDateRedeem(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                      }}
                      // disablePast
                      format="DD/MM/YYYY HH:mm"
                    // showTodayButton
                    />
                  </MuiPickersUtilsProvider>*/}

                  {/*<TextField
                    id="datetime-local"
                    type="datetime-local"
                    format="DD/MM/YYYY HH:mm"
                    className={classes.textField}
                    min="2019-02-18T10:30:55" 
                    disabled={!startDateDisplay}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={endDateDisplay}
                    onChange={(data) =>
                      setEndDateDisplay(data.target.value)
                    }
                  />*/}
                  <div className="form-inline" style={{ marginBottom: "25px" }}>
                    <input id="datestart" disabled={!startDateDisplay} value={endDateDisplay} type="datetime-local" min={startDateDisplay} defaultValue="DD/MM/YYYYTHH:mm" style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(data) => {
                        setEndDateDisplay(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))

                        setEndDateRedeem(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))

                        setend_date_q(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))
                        setend_date_qm(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))
                      }

                      }
                    />
                  </div>
                </div>

                {/* <div className="d-flex justify-content-between mr-3 mb-3">
                  <a style={{ color: "#000", fontSize: "16px" }}></a>
                  <button className="btn btn-primary" onClick={() => openPeriod()}>Set Period</button>
                </div> */}

                {/* <div className="m-5">
                  <Card>
                    <BootstrapTable
                      keyField="id"
                      data={dialogTable}
                      columns={columns_d}
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                    />
                  </Card>
                </div> */}
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Redeem</Typography>
                </AccordionSummary>


                <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    Start Date:{" "}
                  </p>
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      // onClose
                      variant="dialog"
                      ampm={false}
                      value={startDateRedeem}
                      minDate={startDateDisplay}
                      maxDate={endDateDisplay}
                      disabled={!startDateDisplay}
                      onChange={(data) => {
                        setStartDateRedeem(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                        setEndDateRedeem(moment(data._d).add(1, 'hours').format("YYYY-MM-DDTHH:mm"));

                      }}
                      // disablePast
                      format="DD/MM/YYYY HH:mm"
                    // showTodayButton
                    />
                    </MuiPickersUtilsProvider>*/}

                  {/* <TextField
                    id="datetime-locals"
                    type="datetime-local"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={startDateRedeem}
                    onChange={(data) =>
                      setStartDateRedeem(data.target.value)}
                  /> */}
                  <div className="form-inline" style={{ marginBottom: "25px" }}>
                    <input id="datestart" type="datetime-local" value={startDateRedeem} min={startDateDisplay} max={endDateDisplay} defaultValue="DD/MM/YYYYTHH:mm" style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(data) => {
                        setStartDateRedeem(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))
                        //setEndDateRedeem(moment(data.target.value).format("YYYY-MM-DDTHH:mm"));
                      }
                      }
                    />
                  </div>
                </div>

                <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    End Date:{" "}
                  </p>
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      // onClose
                      variant="dialog"
                      ampm={false}
                      minDate={startDateDisplay}
                      maxDate={endDateDisplay}
                      value={endDateRedeem}
                      disabled={!startDateDisplay}
                      onChange={(data) => {
                        setEndDateRedeem(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                      }}
                      // disablePast
                      format="DD/MM/YYYY HH:mm"
                    // showTodayButton
                    />
                  </MuiPickersUtilsProvider>*/}

                  <div className="form-inline" style={{ marginBottom: "25px" }}>
                    <input id="datestart" type="datetime-local" value={endDateRedeem} min={startDateDisplay} max={endDateDisplay} defaultValue="DD/MM/YYYYTHH:mm" style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(data) => {
                        setEndDateRedeem(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))
                      }
                      }
                    />
                  </div>
                  {/* <TextField
                    id="datetime-local"
                    type="datetime-local"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={endDateRedeem}
                    onChange={(data) =>
                      setEndDateRedeem(data.target.value)
                    }
                  /> */}
                </div>

                {/* <div className="d-flex justify-content-between mr-3 mb-3">
                  <a style={{ color: "#000", fontSize: "16px" }}></a>
                  <button className="btn btn-primary" onClick={() => openPeriodRe()}>Set Period</button>
                </div> */}

                {/* <div className="m-5">
                  <Card>
                    <BootstrapTable
                      keyField="id"
                      data={dialogTableRe}
                      columns={columns_d}
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                    />
                  </Card>
                </div> */}
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Quota campaign</Typography>
                </AccordionSummary>

                <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    Start Date:{" "}
                  </p>
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      // onClose
                      placeholder="DD/MM/YYYY HH:mm"
                      variant="dialog"
                      ampm={false}
                      minDate={startDateDisplay}
                      maxDate={endDateDisplay}
                      value={start_date_q}
                      disabled={!startDateDisplay}
                      onChange={(data) => {
                        setstart_date_q(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                        setend_date_q(moment(data._d).add(1, 'hours').format("YYYY-MM-DDTHH:mm"));

                      }}
                      // disablePast
                      format="DD/MM/YYYY HH:mm"
                    // show
                    />
                  </MuiPickersUtilsProvider>*/}
                  <div className="form-inline" style={{ marginBottom: "25px" }}>
                    <input id="datestart" type="datetime-local" defaultValue="DD/MM/YYYYTHH:mm" min={startDateDisplay} max={endDateDisplay} value={start_date_q} disabled={!startDateDisplay} style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(data) => {
                        setstart_date_q(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))
                        setend_date_q(moment(data.target.value).format("YYYY-MM-DDTHH:mm"));
                        setstart_date_qm(moment(data.target.value).format("YYYY-MM-DDTHH:mm"));
                        setend_date_qm(moment(data.target.value).format("YYYY-MM-DDTHH:mm"));
                      }
                      }
                    />
                  </div>


                  {/* <TextField
                    id="datetime-locals"
                    type="datetime-local"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={start_date_q}
                    onChange={(data) =>
                      setstart_date_q(data.target.value)
                    }
                  /> */}
                </div>

                <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    End Date:{" "}
                  </p>
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      // onClose
                      placeholder="DD/MM/YYYY HH:mm"
                      variant="dialog"
                      ampm={false}
                      minDate={startDateDisplay}
                      maxDate={endDateDisplay}
                      value={end_date_q}
                      disabled={!startDateDisplay}
                      onChange={(data) => {
                        setend_date_q(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                      }}
                      // disablePast
                      format="DD/MM/YYYY HH:mm"
                    // show
                    />
                  </MuiPickersUtilsProvider>*/}

                  <div className="form-inline" style={{ marginBottom: "25px" }}>
                    <input id="datestart" type="datetime-local" defaultValue="DD/MM/YYYYTHH:mm" value={end_date_q} disabled={!startDateDisplay} min={startDateDisplay} max={endDateDisplay} style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(data) => {
                        setend_date_q(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))
                        setend_date_qm(moment(data.target.value).format("YYYY-MM-DDTHH:mm"));
                      }
                      }
                    />
                  </div>
                  {/* <TextField
                    id="datetime-local"
                    type="datetime-local"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={end_date_q}
                    onChange={(data) =>
                      setend_date_q(data.target.value)
                    }
                  /> */}
                </div>

                <div
                  className="form-inline m-5"
                >
                  <span
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    Period Limit<span style={{ color: "red" }}>
                      *
                    </span>:{" "}
                  </span>
                  <input
                    type="number"
                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                    style={{
                      height: "38px",
                      width: "230px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "20px"
                    }}
                    value={period_limit_q ? period_limit_q : ""}
                    onChange={(text) => {
                      if (text.target.value > 0 || text.target.value == '') {
                        setperiod_limit_q(text.target.value)
                        setamount(text.target.value)
                      }
                    }}
                  />
                </div>

                <div
                  className="form-inline m-5"
                >
                  <span
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    Year Limit:{" "}
                  </span>
                  <input
                    type="number"
                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                    style={{
                      height: "38px",
                      width: "230px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "20px"
                    }}
                    value={year_limit_q ? year_limit_q : ""}
                    onChange={(text) => {
                      if (text.target.value > 0 || text.target.value == '') {
                        setyear_limit_q(text.target.value)
                      }
                    }}
                  />
                </div>

                <div
                  className="form-inline m-5"
                >
                  <span
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    Month Limit:{" "}
                  </span>
                  <input
                    type="number"
                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                    style={{
                      height: "38px",
                      width: "230px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "20px"
                    }}
                    value={month_limit_q ? month_limit_q : ""}
                    onChange={(text) => {
                      if (text.target.value > 0 || text.target.value == '') {
                        setmonth_limit_q(text.target.value)
                      }
                    }}
                  />
                </div>

                <div
                  className="form-inline m-5"
                >
                  <span
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    Week Limit:{" "}
                  </span>
                  <input
                    type="number"
                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                    style={{
                      height: "38px",
                      width: "230px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "20px"
                    }}
                    value={week_limit_q ? week_limit_q : ""}
                    onChange={(text) => {
                      if (text.target.value > 0 || text.target.value == '') {
                        setweek_limit_q(text.target.value)
                      }
                    }}
                  />
                  <select
                    disabled={week_limit_q ? false : true}
                    as="select"
                    style={{
                      height: "38px",
                      width: "100px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    onChange={(week_day) => handleChangeWeekDay(week_day.target.value)}
                    value={week_day ? week_day : null}
                    defaultValue={week_day ? week_day : null}
                  >
                    <option value={null}>{"Please select"}</option>
                    <option value={0}>{"Sunday"}</option>
                    <option value={1}>{"Monday"}</option>
                    <option value={2}>{"Tuesday"}</option>
                    <option value={3}>{"Wednesday"}</option>
                    <option value={4}>{"Thursday"}</option>
                    <option value={5}>{"Friday"}</option>
                    <option value={6}>{"Saturday"}</option>
                  </select>
                </div>

                <div
                  className="form-inline m-5"
                >
                  <span
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    Day Limit:{" "}
                  </span>
                  <input
                    type="number"
                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                    style={{
                      height: "38px",
                      width: "230px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "20px"
                    }}
                    value={day_limit_q ? day_limit_q : ""}
                    onChange={(text) => {
                      if (text.target.value > 0 || text.target.value == '') {
                        setday_limit_q(text.target.value)
                      }
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between mr-3 mb-3">
                  <a style={{ color: "#000", fontSize: "16px" }}></a>
                  <button className="btn btn-primary" onClick={() => addCampaingn()}>Add</button>
                </div>
                <div className="m-5">
                  <Card>
                    <BootstrapTable
                      keyField="id"
                      data={campaignData}
                      columns={columns}
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                    />
                  </Card>
                </div>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography className={classes.heading}>Quota member</Typography>
                </AccordionSummary>

                <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    Start Date:{" "}
                  </p>
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      // onClose
                      variant="dialog"
                      ampm={false}
                      minDate={startDateDisplay}
                      maxDate={endDateDisplay}
                      value={start_date_qm}
                      disabled={!startDateDisplay}
                      onChange={(data) => {
                        setstart_date_qm(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                        setend_date_qm(moment(data._d).add(1, 'hours').format("YYYY-MM-DDTHH:mm"));

                      }}
                      // disablePast
                      format="DD/MM/YYYY HH:mm"
                    // show
                    />
                  </MuiPickersUtilsProvider>*/}
                  <div className="form-inline" style={{ marginBottom: "25px" }}>
                    <input id="datestart" type="datetime-local" defaultValue="DD/MM/YYYYTHH:mm" value={start_date_qm} disabled={!startDateDisplay} min={startDateDisplay} max={endDateDisplay} style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(data) => {
                        setstart_date_qm(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))
                        setend_date_qm(moment(data.target.value).format("YYYY-MM-DDTHH:mm"));

                      }
                      }
                    />
                  </div>
                  {/* <TextField
                    id="datetime-locals"
                    type="datetime-local"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={start_date_qm}
                    onChange={(data) =>
                      setstart_date_qm(data.target.value)
                    }
                  /> */}
                </div>

                <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                  <p style={{ marginTop: "15px", float: "left", width: "20%" }}>
                    End Date:{" "}
                  </p>
                  {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                      // onClose
                      variant="dialog"
                      ampm={false}
                      minDate={startDateDisplay}
                      maxDate={endDateDisplay}
                      value={end_date_qm}
                      disabled={!startDateDisplay}
                      onChange={(data) => {
                        setend_date_qm(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                      }}
                      // disablePast
                      format="DD/MM/YYYY HH:mm"
                    // show
                    />
                  </MuiPickersUtilsProvider>*/}
                  <div className="form-inline" style={{ marginBottom: "25px" }}>
                    <input id="datestart" type="datetime-local" defaultValue="DD/MM/YYYYTHH:mm" value={end_date_qm} disabled={!startDateDisplay} min={startDateDisplay} max={endDateDisplay} style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={(data) => {
                        setend_date_qm(moment(data.target.value).format("YYYY-MM-DDTHH:mm"));

                      }
                      }
                    />
                  </div>

                  {/* <TextField
                    id="datetime-local"
                    type="datetime-local"
                    className={classes.textField}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={end_date_qm}
                    onChange={(data) =>
                      setend_date_qm(data.target.value)
                    }
                  /> */}
                </div>

                <div
                  className="form-inline m-5"
                >
                  <span
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    Period Limit<span style={{ color: "red" }}>
                      *
                    </span>:{" "}
                  </span>
                  <input
                    type="number"
                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                    style={{
                      height: "38px",
                      width: "230px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "20px"
                    }}
                    value={period_limit_qm ? period_limit_qm : ""}
                    onChange={(text) => {
                      if (text.target.value > 0 || text.target.value == '') {
                        setperiod_limit_qm(text.target.value)
                      }
                    }}
                  />
                </div>

                <div
                  className="form-inline m-5"
                >
                  <span
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    Year Limit:{" "}
                  </span>
                  <input
                    type="number"
                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                    style={{
                      height: "38px",
                      width: "230px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "20px"
                    }}
                    value={year_limit_qm ? year_limit_qm : ""}
                    onChange={(text) => {
                      if (text.target.value > 0 || text.target.value == '') {
                        setyear_limit_qm(text.target.value)
                      }
                    }}
                  />
                </div>

                <div
                  className="form-inline m-5"
                >
                  <span
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    Month Limit:{" "}
                  </span>
                  <input
                    type="number"
                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                    style={{
                      height: "38px",
                      width: "230px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "20px"
                    }}
                    value={month_limit_qm ? month_limit_qm : ""}
                    onChange={(text) => {
                      if (text.target.value > 0 || text.target.value == '') {
                        setmonth_limit_qm(text.target.value)
                      }
                    }}
                  />
                </div>

                <div
                  className="form-inline m-5"
                >
                  <span
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    Week Limit:{" "}
                  </span>
                  <input
                    type="number"
                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                    style={{
                      height: "38px",
                      width: "230px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "20px"
                    }}
                    value={week_limit_qm ? week_limit_qm : ""}
                    onChange={(text) => {
                      if (text.target.value > 0 || text.target.value == '') {
                        setweek_limit_qm(text.target.value)
                      }
                    }}
                  />
                  <select
                    disabled={week_limit_qm ? false : true}
                    as="select"
                    style={{
                      height: "38px",
                      width: "100px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                    }}
                    onChange={(week_day_qm) => handleChangeWeekDayQm(week_day_qm.target.value)}
                    value={week_day_qm ? week_day_qm : null}
                    defaultValue={week_day_qm ? week_day_qm : null}
                  >
                    <option value={null}>{"Please select"}</option>
                    <option value={0}>{"Sunday"}</option>
                    <option value={1}>{"Monday"}</option>
                    <option value={2}>{"Tuesday"}</option>
                    <option value={3}>{"Wednesday"}</option>
                    <option value={4}>{"Thursday"}</option>
                    <option value={5}>{"Friday"}</option>
                    <option value={6}>{"Saturday"}</option>
                  </select>
                </div>

                <div
                  className="form-inline m-5"
                >
                  <span
                    style={{
                      marginTop: "15px",
                      float: "left",
                      width: "20%",
                    }}
                  >
                    Day Limit:{" "}
                  </span>
                  <input
                    type="number"
                    onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                    style={{
                      height: "38px",
                      width: "230px",
                      backgroundColor: "#F4F6F9",
                      borderRadius: "5px",
                      borderWidth: "0",
                      marginRight: "20px"
                    }}
                    value={day_limit_qm ? day_limit_qm : ""}
                    onChange={(text) => {
                      if (text.target.value > 0 || text.target.value == '') {
                        setday_limit_qm(text.target.value)
                      }
                    }}
                  />
                </div>
                <div className="d-flex justify-content-between mr-3 mb-3">
                  <a style={{ color: "#000", fontSize: "16px" }}></a>
                  <button className="btn btn-primary" onClick={() => addMember()}>Add</button>
                </div>
                <div className="m-5">
                  <Card>
                    <BootstrapTable
                      keyField="id"
                      data={memberData}
                      columns={columns_m}
                      wrapperClasses="table-responsive"
                      bordered={false}
                      classes="table table-head-custom table-vertical-center overflow-hidden"
                      bootstrap4
                    />
                  </Card>
                </div>
              </Accordion>
            </div>

          </CardBody>
        </Card>

        <Modal
          open={openEditCampaign}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body my_scroll_divs">
                <div className="card " style={{ width: "100%" }}>
                  <div className="card-body">
                    <h5 className="card-title">Edit Campaign</h5>
                    <div
                      className="form-inline"
                    >
                      <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "30%" }}>
                          Start Date:{" "}
                        </p>
                        <div className="form-inline" style={{ marginBottom: "25px" }}>
                          <input id="datestart" type="datetime-local" value={start_date_campaign} defaultValue="DD/MM/YYYYTHH:mm" style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            min={startDateDisplay}
                            onChange={(data) => {
                              console.log(data.target.value)
                              setstart_date_campaign(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))


                              /*if (!endDateRedeem) {
                                setEndDateRedeem(moment(data.target.value).format("YYYY-MM-DDTHH:mm"));
                              }*/

                            }

                            }
                          />
                        </div>
                        {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDateTimePicker
                            variant="dialog"
                            ampm={false}
                            value={start_date_campaign}
                            minDate={startDateDisplay}
                            onChange={(data) => {
                              setstart_date_campaign(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                            }}
                            format="DD/MM/YYYY HH:mm"
                          />
                        </MuiPickersUtilsProvider>*/}

                      </div>

                      <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "30%" }}>
                          End Date:{" "}
                        </p>
                        <div className="form-inline" style={{ marginBottom: "25px" }}>
                          <input id="datestart" type="datetime-local" value={end_date_campaign} defaultValue="DD/MM/YYYYTHH:mm" style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            min={startDateDisplay}
                            onChange={(data) => {
                              console.log(data.target.value)
                              setend_date_campaign(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))


                              /*if (!endDateRedeem) {
                                setEndDateRedeem(moment(data.target.value).format("YYYY-MM-DDTHH:mm"));
                              }*/

                            }

                            }
                          />
                        </div>
                        {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDateTimePicker
                            variant="dialog"
                            ampm={false}
                            value={end_date_campaign}
                            minDate={startDateDisplay}
                            onChange={(data) => {
                              setend_date_campaign(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                            }}
                            format="DD/MM/YYYY HH:mm"
                          />
                        </MuiPickersUtilsProvider>*/}

                      </div>

                      <div
                        className="form-inline m-5"
                      >
                        <span
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "30%",
                          }}
                        >
                          Period Limit:{" "}
                        </span>
                        <input
                          type="number"
                          onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                          style={{
                            height: "38px",
                            width: "230px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                            marginRight: "20px"
                          }}
                          value={period_limit_campaign}
                          onChange={(text) => {
                            setperiod_limit_campaign(text.target.value)
                          }}
                        />
                      </div>

                      <div
                        className="form-inline m-5"
                      >
                        <span
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "30%",
                          }}
                        >
                          Year Limit:{" "}
                        </span>
                        <input
                          type="number"
                          onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                          style={{
                            height: "38px",
                            width: "230px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                            marginRight: "20px"
                          }}
                          value={year_limit_campaign}
                          onChange={(text) => setyear_limit_campaign(text.target.value)}
                        />
                      </div>

                      <div
                        className="form-inline m-5"
                      >
                        <span
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "30%",
                          }}
                        >
                          Month Limit:{" "}
                        </span>
                        <input
                          type="number"
                          onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                          style={{
                            height: "38px",
                            width: "230px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                            marginRight: "20px"
                          }}
                          value={month_limit_campaign}
                          onChange={(text) => setmonth_limit_campaign(text.target.value)}
                        />
                      </div>

                      <div
                        className="form-inline m-5"
                      >
                        <span
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "30%",
                          }}
                        >
                          Week Limit:{" "}
                        </span>
                        <input
                          type="number"
                          onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                          style={{
                            height: "38px",
                            width: "230px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                            marginRight: "20px"
                          }}
                          value={week_limit_campaign}
                          onChange={(text) => setweek_limit_campaign(text.target.value)}
                        />
                      </div>
                      <div
                        className="form-inline m-5"
                      >
                        <select
                          disabled={week_limit_campaign ? false : true}
                          as="select"
                          style={{
                            height: "38px",
                            width: "100px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                          }}
                          onChange={(week_day_limit_campaign) => handleChangeWeekDayCampaign(week_day_limit_campaign.target.value)}
                          value={week_day_limit_campaign ? week_day_limit_campaign : null}
                          defaultValue={week_day_limit_campaign ? week_day_limit_campaign : null}
                        >
                          <option value={null}>{"Please select"}</option>
                          <option value={0}>{"Sunday"}</option>
                          <option value={1}>{"Monday"}</option>
                          <option value={2}>{"Tuesday"}</option>
                          <option value={3}>{"Wednesday"}</option>
                          <option value={4}>{"Thursday"}</option>
                          <option value={5}>{"Friday"}</option>
                          <option value={6}>{"Saturday"}</option>
                        </select>
                      </div>
                      <div
                        className="form-inline m-5"
                      >
                        <span
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "30%",
                          }}
                        >
                          Day Limit:{" "}
                        </span>
                        <input
                          type="number"
                          onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                          style={{
                            height: "38px",
                            width: "230px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                            marginRight: "20px"
                          }}
                          value={day_limit_campaign}
                          onChange={(text) => setday_limit_campaign(text.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    color: "#3b4fbc",
                    fontSize: "16px",
                    textAlign: "right",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => confrimCampaign()}
                  >
                    Confrim
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleCloseCampaign()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          open={openEditMember}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-body my_scroll_divs">
                <div className="card " style={{ width: "100%" }}>
                  <div className="card-body">
                    <h5 className="card-title">Edit Member</h5>
                    <div
                      className="form-inline"
                    >
                      <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "30%" }}>
                          Start Date:{" "}
                        </p>
                        <div className="form-inline" style={{ marginBottom: "25px" }}>
                          <input id="datestart" type="datetime-local" value={start_date_member} defaultValue="DD/MM/YYYYTHH:mm" style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            min={startDateDisplay}
                            onChange={(data) => {
                              console.log(data.target.value)
                              setstart_date_member(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))


                              /*if (!endDateRedeem) {
                                setEndDateRedeem(moment(data.target.value).format("YYYY-MM-DDTHH:mm"));
                              }*/

                            }

                            }
                          />
                        </div>
                        {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDateTimePicker
                            variant="dialog"
                            ampm={false}
                            value={start_date_member}
                            minDate={startDateDisplay}
                            onChange={(data) => {
                              setstart_date_member(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                            }}
                            format="DD/MM/YYYY HH:mm"
                          />
                        </MuiPickersUtilsProvider>*/}
                      </div>

                      <div className="form-inline m-5" style={{ marginBottom: "25px" }}>
                        <p style={{ marginTop: "15px", float: "left", width: "30%" }}>
                          End Date:{" "}
                        </p>
                        <div className="form-inline" style={{ marginBottom: "25px" }}>
                          <input id="datestart" type="datetime-local" value={end_date_member} defaultValue="DD/MM/YYYYTHH:mm" style={{ height: "38px", width: "185px", backgroundColor: "#F4F6F9", borderRadius: "5px", borderWidth: "0" }}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            min={startDateDisplay}
                            onChange={(data) => {
                              console.log(data.target.value)
                              setend_date_member(moment(data.target.value).format("YYYY-MM-DDTHH:mm"))


                              /*if (!endDateRedeem) {
                                setEndDateRedeem(moment(data.target.value).format("YYYY-MM-DDTHH:mm"));
                              }*/

                            }

                            }
                          />
                        </div>
                        {/*<MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDateTimePicker
                            variant="dialog"
                            ampm={false}
                            value={end_date_member}
                            minDate={startDateDisplay}
                            onChange={(data) => {
                              setend_date_member(moment(data._d).format("YYYY-MM-DDTHH:mm"))
                            }}
                            format="DD/MM/YYYY HH:mm"
                          />
                        </MuiPickersUtilsProvider>*/}

                      </div>

                      <div
                        className="form-inline m-5"
                      >
                        <span
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "30%",
                          }}
                        >
                          Period Limit:{" "}
                        </span>
                        <input
                          type="number"
                          onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                          style={{
                            height: "38px",
                            width: "230px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                            marginRight: "20px"
                          }}
                          value={period_limit_member}
                          onChange={(text) => setperiod_limit_member(text.target.value)}
                        />
                      </div>

                      <div
                        className="form-inline m-5"
                      >
                        <span
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "30%",
                          }}
                        >
                          Year Limit:{" "}
                        </span>
                        <input
                          type="number"
                          onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                          style={{
                            height: "38px",
                            width: "230px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                            marginRight: "20px"
                          }}
                          value={year_limit_member}
                          onChange={(text) => setyear_limit_member(text.target.value)}
                        />
                      </div>

                      <div
                        className="form-inline m-5"
                      >
                        <span
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "30%",
                          }}
                        >
                          Month Limit:{" "}
                        </span>
                        <input
                          type="number"
                          onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                          style={{
                            height: "38px",
                            width: "230px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                            marginRight: "20px"
                          }}
                          value={month_limit_member}
                          onChange={(text) => setmonth_limit_member(text.target.value)}
                        />
                      </div>

                      <div
                        className="form-inline m-5"
                      >
                        <span
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "30%",
                          }}
                        >
                          Week Limit:{" "}
                        </span>
                        <input
                          type="number"
                          onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                          style={{
                            height: "38px",
                            width: "230px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                            marginRight: "20px"
                          }}
                          value={week_limit_member}
                          onChange={(text) => setweek_limit_member(text.target.value)}
                        />
                      </div>
                      <div
                        className="form-inline m-5"
                      >
                        <select
                          disabled={week_limit_member ? false : true}
                          as="select"
                          style={{
                            height: "38px",
                            width: "100px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                          }}
                          onChange={(week_day_limit_member) => handleChangeWeekDayMember(week_day_limit_member.target.value)}
                          value={week_day_limit_member ? week_day_limit_member : null}
                          defaultValue={week_day_limit_member ? week_day_limit_member : null}
                        >
                          <option value={null}>{"Please select"}</option>
                          <option value={0}>{"Sunday"}</option>
                          <option value={1}>{"Monday"}</option>
                          <option value={2}>{"Tuesday"}</option>
                          <option value={3}>{"Wednesday"}</option>
                          <option value={4}>{"Thursday"}</option>
                          <option value={5}>{"Friday"}</option>
                          <option value={6}>{"Saturday"}</option>
                        </select>
                      </div>
                      <div
                        className="form-inline m-5"
                      >
                        <span
                          style={{
                            marginTop: "15px",
                            float: "left",
                            width: "30%",
                          }}
                        >
                          Day Limit:{" "}
                        </span>
                        <input
                          type="number"
                          onKeyDown={e => symbolsArr.includes(e.key) && e.preventDefault()}

                          style={{
                            height: "38px",
                            width: "230px",
                            backgroundColor: "#F4F6F9",
                            borderRadius: "5px",
                            borderWidth: "0",
                            marginRight: "20px"
                          }}
                          value={day_limit_member}
                          onChange={(text) => setday_limit_member(text.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    color: "#3b4fbc",
                    fontSize: "16px",
                    textAlign: "right",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={() => confrimMember()}
                  >
                    Confrim
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleCloseMember()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>



        <Modal
          open={openDialogModalTable}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog-full-width modal-dialog momodel modal-fluid">
            <div className="modal-content">
              <div className="modal-body my_scroll_divs">
                <div className="card " style={{ width: "100%" }}>
                  <div className="card-body">
                    <h5 className="card-title">Dialog Datetime</h5>
                    <div
                      className="form-inline"
                    >
                      <div
                        className="table"
                        style={{ width: "100%" }}
                      >
                        {
                          <>
                            {/*Mon*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedMon}
                                  onChange={handleChangeCheckMon}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Mon
                              </div>

                              {checkedMon ?
                                <>
                                  {
                                    mons
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{mons.map((item, index) => (
                                          <>
                                            <div className="table-row mt-1 ml-4" key={index}>
                                              <span className="mt-3 ml-1 mr-1">{index + 1} </span>
                                              <input
                                                className="m-1"
                                                type="time"
                                                name="start_time"
                                                data-id={index}
                                                value={item.start_time}
                                                onChange={handleMonsChange}
                                                style={{
                                                  backgroundColor: "#F4F6F9",
                                                  borderRadius: "5px",
                                                  borderWidth: "0",
                                                  width: "50%",
                                                  height: "30px"
                                                }}
                                              />
                                              <input
                                                className="m-1"
                                                type="time"
                                                name="end_time"
                                                data-id={index}
                                                value={item.end_time}
                                                onChange={handleMonsChange}
                                                style={{
                                                  backgroundColor: "#F4F6F9",
                                                  borderRadius: "5px",
                                                  borderWidth: "0",
                                                  width: "50%",
                                                  height: "30px"
                                                }}
                                              />
                                              {
                                                index === 0 ?
                                                  null :
                                                  <button
                                                    className="btn btn-danger"
                                                    type="button"
                                                    onClick={() => RemoveMonsFields(index)}
                                                  >
                                                    -
                                                  </button>
                                              }
                                            </div>
                                            {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                            {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                            {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                          </>
                                        ))}
                                      </>
                                      : null}
                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewMons}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>

                            {/*Tue*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedTue}
                                  onChange={handleChangeCheckTue}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Tue
                              </div>
                              {checkedTue ?
                                <>
                                  {
                                    tues
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{
                                          tues.map((item, index) => (
                                            <>
                                              <div className="table-row mt-1 ml-4" key={index}>
                                                <span className="mt-3 ml-1 mr-1">{index + 1} </span>

                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="start_time"
                                                  data-id={index}
                                                  value={item.start_time}
                                                  onChange={handleTuesChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="end_time"
                                                  data-id={index}
                                                  value={item.end_time}
                                                  onChange={handleTuesChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                {
                                                  index === 0 ?
                                                    null :
                                                    <button
                                                      className="btn btn-danger"
                                                      type="button"
                                                      onClick={() => RemoveTuesFields(index)}
                                                    >
                                                      -
                                                    </button>
                                                }


                                              </div>
                                              {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                              {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                              {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                            </>
                                          ))}
                                      </>
                                      : null}

                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewTues}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>

                            {/*Wed*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedWed}
                                  onChange={handleChangeCheckWed}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Wed
                              </div>
                              {checkedWed ?
                                <>
                                  {
                                    weds
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{
                                          weds.map((item, index) => (
                                            <>

                                              <div className="table-row mt-1 ml-4" key={index}>
                                                <span className="mt-3 ml-1 mr-1">{index + 1} </span>

                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="start_time"
                                                  data-id={index}
                                                  value={item.start_time}
                                                  onChange={handleWedsChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="end_time"
                                                  data-id={index}
                                                  value={item.end_time}
                                                  onChange={handleWedsChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                {
                                                  index === 0 ?
                                                    null :
                                                    <button
                                                      className="btn btn-danger"
                                                      type="button"
                                                      onClick={() => RemoveWedsFields(index)}
                                                    >
                                                      -
                                                    </button>
                                                }
                                              </div>
                                              {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                              {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                              {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                            </>
                                          ))}
                                      </>
                                      : null}

                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewWeds}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>

                            {/*Thu*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedThu}
                                  onChange={handleChangeCheckThu}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Thu
                              </div>
                              {checkedThu ?
                                <>
                                  {
                                    thus
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{
                                          thus.map((item, index) => (
                                            <>
                                              <div className="table-row mt-1 ml-4" key={index}>
                                                <span className="mt-3 ml-1 mr-1">{index + 1} </span>

                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="start_time"
                                                  data-id={index}
                                                  value={item.start_time}
                                                  onChange={handleThusChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="end_time"
                                                  data-id={index}
                                                  value={item.end_time}
                                                  onChange={handleThusChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                {
                                                  index === 0 ?
                                                    null :
                                                    <button
                                                      className="btn btn-danger"
                                                      type="button"
                                                      onClick={() => RemoveThusFields(index)}
                                                    >
                                                      -
                                                    </button>
                                                }


                                              </div>
                                              {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                              {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                              {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                            </>
                                          ))}
                                      </>
                                      : null}

                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewThus}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>

                            {/*Fri*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedFri}
                                  onChange={handleChangeCheckFri}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Fri
                              </div>
                              {checkedFri ?
                                <>
                                  {
                                    fris
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{
                                          fris.map((item, index) => (
                                            <>

                                              <div className="table-row mt-1 ml-4" key={index}>
                                                <span className="mt-3 ml-1 mr-1">{index + 1} </span>

                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="start_time"
                                                  data-id={index}
                                                  value={item.start_time}
                                                  onChange={handleFrisChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="end_time"
                                                  data-id={index}
                                                  value={item.end_time}
                                                  onChange={handleFrisChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                {
                                                  index === 0 ?
                                                    null :
                                                    <button
                                                      className="btn btn-danger"
                                                      type="button"
                                                      onClick={() => RemoveFrisFields(index)}
                                                    >
                                                      -
                                                    </button>
                                                }


                                              </div>
                                              {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                              {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                              {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                            </>
                                          ))}
                                      </>
                                      : null}

                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewFris}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>

                            {/*Sat*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedSat}
                                  onChange={handleChangeCheckSat}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Sat
                              </div>
                              {checkedSat ?
                                <>
                                  {
                                    sats
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{
                                          sats.map((item, index) => (
                                            <>

                                              <div className="table-row mt-1 ml-4" key={index}>
                                                <span className="mt-3 ml-1 mr-1">{index + 1} </span>


                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="start_time"
                                                  data-id={index}
                                                  value={item.start_time}
                                                  onChange={handleSatsChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="end_time"
                                                  data-id={index}
                                                  value={item.end_time}
                                                  onChange={handleSatsChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                {
                                                  index === 0 ?
                                                    null :
                                                    <button
                                                      className="btn btn-danger"
                                                      type="button"
                                                      onClick={() => RemoveSatsFields(index)}
                                                    >
                                                      -
                                                    </button>
                                                }


                                              </div>
                                              {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                              {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                              {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                            </>
                                          ))}
                                      </>
                                      : null}

                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewSats}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>

                            {/*Sun*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedSun}
                                  onChange={handleChangeCheckSun}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Sun
                              </div>
                              {checkedSun ?
                                <>
                                  {
                                    suns
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{
                                          suns.map((item, index) => (
                                            <>

                                              <div className="table-row mt-1 ml-4" key={index}>
                                                <span className="mt-3 ml-1 mr-1">{index + 1} </span>


                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="start_time"
                                                  data-id={index}
                                                  value={item.start_time}
                                                  onChange={handleSunsChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="end_time"
                                                  data-id={index}
                                                  value={item.end_time}
                                                  onChange={handleSunsChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                {
                                                  index === 0 ?
                                                    null :
                                                    <button
                                                      className="btn btn-danger"
                                                      type="button"
                                                      onClick={() => RemoveSunsFields(index)}
                                                    >
                                                      -
                                                    </button>
                                                }


                                              </div>
                                              {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                              {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                              {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                            </>
                                          ))}
                                      </>
                                      : null}
                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewSuns}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>
                          </>
                        }
                      </div>

                    </div>


                  </div>
                </div>

                <div
                  style={{
                    color: "#3b4fbc",
                    fontSize: "16px",
                    textAlign: "right",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!checkedError}
                    onClick={() => confrim()}
                  >
                    Confrim
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleClose()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>


        <Modal
          open={openDialogModalReTable}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
        >
          <div className="modal-dialog-full-width modal-dialog momodel modal-fluid">
            <div className="modal-content">
              <div className="modal-body my_scroll_divs">

                <div className="card " style={{ width: "100%" }}>
                  <div className="card-body">
                    <h5 className="card-title">Dialog Datetime</h5>
                    <div
                      className="form-inline"
                    >
                      <div
                        className="table"
                        style={{ width: "100%" }}
                      >
                        {
                          <>
                            {/*Mon*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedMonRe}
                                  onChange={handleChangeCheckMonRe}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Mon
                              </div>

                              {checkedMonRe ?
                                <>
                                  {
                                    monRes
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{monRes.map((item, index) => (
                                          <>
                                            <div className="table-row mt-1 ml-4" key={index}>
                                              <span className="mt-3 ml-1 mr-1">{index + 1} </span>
                                              <input
                                                className="m-1"
                                                type="time"
                                                name="start_time"
                                                data-id={index}
                                                value={item.start_time}
                                                onChange={handleMonResChange}
                                                style={{
                                                  backgroundColor: "#F4F6F9",
                                                  borderRadius: "5px",
                                                  borderWidth: "0",
                                                  width: "50%",
                                                  height: "30px"
                                                }}
                                              />
                                              <input
                                                className="m-1"
                                                type="time"
                                                name="end_time"
                                                data-id={index}
                                                value={item.end_time}
                                                onChange={handleMonResChange}
                                                style={{
                                                  backgroundColor: "#F4F6F9",
                                                  borderRadius: "5px",
                                                  borderWidth: "0",
                                                  width: "50%",
                                                  height: "30px"
                                                }}
                                              />
                                              {
                                                index === 0 ?
                                                  null :
                                                  <button
                                                    className="btn btn-danger"
                                                    type="button"
                                                    onClick={() => RemoveMonResFields(index)}
                                                  >
                                                    -
                                                  </button>
                                              }
                                            </div>
                                            {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                            {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                            {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                          </>
                                        ))}
                                      </>
                                      : null}
                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewMonRes}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>

                            {/*Tue*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedTueRe}
                                  onChange={handleChangeCheckTueRe}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Tue
                              </div>
                              {checkedTueRe ?
                                <>
                                  {
                                    tueRes
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{
                                          tueRes.map((item, index) => (
                                            <>
                                              <div className="table-row mt-1 ml-4" key={index}>
                                                <span className="mt-3 ml-1 mr-1">{index + 1} </span>

                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="start_time"
                                                  data-id={index}
                                                  value={item.start_time}
                                                  onChange={handleTueResChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="end_time"
                                                  data-id={index}
                                                  value={item.end_time}
                                                  onChange={handleTueResChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                {
                                                  index === 0 ?
                                                    null :
                                                    <button
                                                      className="btn btn-danger"
                                                      type="button"
                                                      onClick={() => RemoveTueResFields(index)}
                                                    >
                                                      -
                                                    </button>
                                                }


                                              </div>
                                              {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                              {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                              {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                            </>
                                          ))}
                                      </>
                                      : null}

                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewTueRes}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>

                            {/*Wed*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedWedRe}
                                  onChange={handleChangeCheckWedRe}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Wed
                              </div>
                              {checkedWedRe ?
                                <>
                                  {
                                    wedRes
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{
                                          wedRes.map((item, index) => (
                                            <>

                                              <div className="table-row mt-1 ml-4" key={index}>
                                                <span className="mt-3 ml-1 mr-1">{index + 1} </span>

                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="start_time"
                                                  data-id={index}
                                                  value={item.start_time}
                                                  onChange={handleWedResChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="end_time"
                                                  data-id={index}
                                                  value={item.end_time}
                                                  onChange={handleWedResChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                {
                                                  index === 0 ?
                                                    null :
                                                    <button
                                                      className="btn btn-danger"
                                                      type="button"
                                                      onClick={() => RemoveWedResFields(index)}
                                                    >
                                                      -
                                                    </button>
                                                }
                                              </div>
                                              {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                              {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                              {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                            </>
                                          ))}
                                      </>
                                      : null}

                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewWedRes}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>

                            {/*Thu*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedThuRe}
                                  onChange={handleChangeCheckThuRe}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Thu
                              </div>
                              {checkedThuRe ?
                                <>
                                  {
                                    thuRes
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{
                                          thuRes.map((item, index) => (
                                            <>
                                              <div className="table-row mt-1 ml-4" key={index}>
                                                <span className="mt-3 ml-1 mr-1">{index + 1} </span>

                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="start_time"
                                                  data-id={index}
                                                  value={item.start_time}
                                                  onChange={handleThuResChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="end_time"
                                                  data-id={index}
                                                  value={item.end_time}
                                                  onChange={handleThuResChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                {
                                                  index === 0 ?
                                                    null :
                                                    <button
                                                      className="btn btn-danger"
                                                      type="button"
                                                      onClick={() => RemoveThuResFields(index)}
                                                    >
                                                      -
                                                    </button>
                                                }


                                              </div>
                                              {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                              {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                              {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                            </>
                                          ))}
                                      </>
                                      : null}

                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewThuRes}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>

                            {/*Fri*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedFriRe}
                                  onChange={handleChangeCheckFriRe}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Fri
                              </div>
                              {checkedFriRe ?
                                <>
                                  {
                                    friRes
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{
                                          friRes.map((item, index) => (
                                            <>

                                              <div className="table-row mt-1 ml-4" key={index}>
                                                <span className="mt-3 ml-1 mr-1">{index + 1} </span>

                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="start_time"
                                                  data-id={index}
                                                  value={item.start_time}
                                                  onChange={handleFriResChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="end_time"
                                                  data-id={index}
                                                  value={item.end_time}
                                                  onChange={handleFriResChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                {
                                                  index === 0 ?
                                                    null :
                                                    <button
                                                      className="btn btn-danger"
                                                      type="button"
                                                      onClick={() => RemoveFriResFields(index)}
                                                    >
                                                      -
                                                    </button>
                                                }


                                              </div>
                                              {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                              {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                              {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                            </>
                                          ))}
                                      </>
                                      : null}

                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewFriRes}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>

                            {/*Sat*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedSatRe}
                                  onChange={handleChangeCheckSatRe}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Sat
                              </div>
                              {checkedSatRe ?
                                <>
                                  {
                                    satRes
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{
                                          satRes.map((item, index) => (
                                            <>

                                              <div className="table-row mt-1 ml-4" key={index}>
                                                <span className="mt-3 ml-1 mr-1">{index + 1} </span>


                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="start_time"
                                                  data-id={index}
                                                  value={item.start_time}
                                                  onChange={handleSatResChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="end_time"
                                                  data-id={index}
                                                  value={item.end_time}
                                                  onChange={handleSatResChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                {
                                                  index === 0 ?
                                                    null :
                                                    <button
                                                      className="btn btn-danger"
                                                      type="button"
                                                      onClick={() => RemoveSatResFields(index)}
                                                    >
                                                      -
                                                    </button>
                                                }


                                              </div>
                                              {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                              {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                              {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                            </>
                                          ))}
                                      </>
                                      : null}

                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewSatRes}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>

                            {/*Sun*/}
                            <div className="table-content">
                              <div>
                                <Checkbox
                                  className="ml-1"
                                  color="primary"
                                  checked={checkedSunRe}
                                  onChange={handleChangeCheckSunRe}
                                  inputProps={{ 'aria-label': 'primary checkbox' }}
                                />Sun
                              </div>
                              {checkedSunRe ?
                                <>
                                  {
                                    sunRes
                                      ?
                                      <>
                                        <div className="table-row mt-1 ml-4">
                                          <span className="mt-3 ml-1 mr-1"></span>
                                          <span style={{
                                            width: "50%"
                                          }}>Start Time</span>
                                          <span style={{
                                            width: "50%"
                                          }}>End Time</span>
                                          <span></span>
                                        </div>{
                                          sunRes.map((item, index) => (
                                            <>

                                              <div className="table-row mt-1 ml-4" key={index}>
                                                <span className="mt-3 ml-1 mr-1">{index + 1} </span>


                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="start_time"
                                                  data-id={index}
                                                  value={item.start_time}
                                                  onChange={handleSunResChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                <input
                                                  className="m-1"
                                                  type="time"
                                                  name="end_time"
                                                  data-id={index}
                                                  value={item.end_time}
                                                  onChange={handleSunResChange}
                                                  style={{
                                                    backgroundColor: "#F4F6F9",
                                                    borderRadius: "5px",
                                                    borderWidth: "0",
                                                    width: "50%",
                                                    height: "30px"
                                                  }}
                                                />
                                                {
                                                  index === 0 ?
                                                    null :
                                                    <button
                                                      className="btn btn-danger"
                                                      type="button"
                                                      onClick={() => RemoveSunResFields(index)}
                                                    >
                                                      -
                                                    </button>
                                                }
                                              </div>
                                              {item.start_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>Start time not null.</span>}
                                              {item.end_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time not null.</span>}
                                              {item.check_validate_time ? null : <span className="mt-1 ml-4" style={{ color: "red" }}>End time must more than start time.</span>}
                                            </>
                                          ))}
                                      </>
                                      : null}
                                  <div className="table-row">
                                    <div className="table-data text-center">
                                      <button className="btn btn-primary" onClick={addNewSunRes}>
                                        +
                                      </button>
                                    </div>
                                  </div>
                                </>
                                : null}
                            </div>
                          </>
                        }
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    color: "#3b4fbc",
                    fontSize: "16px",
                    textAlign: "right",
                  }}
                >
                  <button
                    type="button"
                    className="btn btn-primary"
                    disabled={!checkedErrorRe}
                    onClick={() => confrimRe()}
                  >
                    Confrim
                  </button>

                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => handleCloseRe()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div >
    )
  }

  const StepTree = () => {
    return (
      <div>
        <Card>
          <CardHeader title="Step 3"></CardHeader>
          <CardBody>

            <div
              className="form-inline"
              style={{
                marginBottom: "25px",
              }}
            >
              <p
                style={{
                  marginTop: "15px",
                  float: "left",
                  width: "20%",
                }}
              >
                Type:{" "}
              </p>
              <select
                as="select"
                style={{
                  height: "38px",
                  width: "45%",
                  backgroundColor: "#F4F6F9",
                  borderRadius: "5px",
                  borderWidth: "0",
                }}
                onChange={(type) => handleChangeType(type.target.value)}
                defaultValue={"auto"}
              >
                <option value="auto">Auto Generate Code</option>
                <option value="upload">Upload Code</option>
              </select>
            </div>

            {type === "auto" ?
              <>
                <h1>Auto Generate Code</h1>
                <div className="container">
                  <div
                    className="form-inline"
                    style={{
                      marginBottom: "25px",
                    }}
                  >
                    <span
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      Length:{" "}
                    </span>
                    <input
                      type="number"
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      value={length}
                      onChange={(length) => setlength(length.target.value)}
                    />
                  </div>

                  <div
                    className="form-inline"
                    style={{
                      marginBottom: "25px",
                    }}
                  >
                    <span
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                      Amount:{" "}
                    </span>
                    <input
                      type="number"
                      style={{
                        height: "38px",
                        width: "530px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      value={amount}
                      onChange={(amount) => setamount(amount.target.value)}
                    />
                  </div>

                  <div className="form-group form-check">
                    <span
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                    </span>
                    <input type="checkbox" className="form-check-input" id="exampleCheck1" onClick={() => setuppercase(!uppercase)} />
                    <label className="form-check-label" for="exampleCheck1">Upper Case</label>
                  </div>
                  <div className="form-group form-check">
                    <span
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                    </span>
                    <input type="checkbox" className="form-check-input" id="exampleCheck2" onClick={() => setlowercase(!lowercase)} />
                    <label className="form-check-label" for="exampleCheck2">Lower Case</label>
                  </div>
                  <div className="form-group form-check">
                    <span
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                    </span>
                    <input type="checkbox" className="form-check-input" id="exampleCheck3" onClick={() => setnumbers(!numbers)} />
                    <label className="form-check-label" for="exampleCheck3">Number</label>
                  </div>
                  <div className="form-group form-check">
                    <span
                      style={{
                        marginTop: "15px",
                        float: "left",
                        width: "20%",
                      }}
                    >
                    </span>
                    <input type="checkbox" className="form-check-input" id="exampleCheck4" onClick={() => setsymbols(!symbols)} />
                    <label className="form-check-label" for="exampleCheck4">Symbol</label>
                  </div>
                </div>
              </>
              :
              <>
                <h1>Upload Code</h1>
                <div className="container">
                  <div className="form-inline">
                    <span style={{ marginTop: "15px", float: "left", width: "20%" }}>
                      Upload file: <br />
                    </span>
                    <input
                      name="name"
                      style={{
                        height: "38px",
                        width: "330px",
                        backgroundColor: "#F4F6F9",
                        borderRadius: "5px",
                        borderWidth: "0",
                      }}
                      disabled
                      value={namefile}
                    />

                    <span className="btn btn-primary btn-file wbt">
                      เลือก / แก้ไข
                      <input type="file" onChange={handleChangeFile} />
                    </span>
                  </div>
                  {isUpload ? (
                    <div className="form-inline" style={{ marginBottom: "25px" }}>
                      <span style={{ float: "left", width: "20%" }}> </span>
                      {progress > 0 ? (
                        <button className="btn btn-primary" type="button" disabled>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          {"  "}Loading...
                        </button>
                      ) : (
                        <>
                          <button className="btn btn-primary mb-5" onClick={() => save()}>
                            Upload
                          </button>
                          <button className="btn btn-danger mb-5"
                            onClick={() => deleteUpload()}
                          >
                            Delete
                          </button>
                        </>
                      )}
                    </div>
                  ) : null}

                  {progress > 0 ? (
                    <div>
                      <div className="progress">
                        <div
                          className="progress-bar progress-bar-striped progress-bar-animatedprogress-bar progress-bar-striped progress-bar-animated"
                          role="progressbar"
                          style={{ width: `${progress}%` }}
                          aria-valuenow={progress}
                          aria-valuemin="0"
                          aria-valuemax="100"
                        >
                          {progress === 100 ? progress : `${progress} %`}
                        </div>
                      </div>
                    </div>
                  ) : null}

                </div>
              </>
            }
          </CardBody>
        </Card>
      </div>
    )
  }

  useEffect(() => {
    console.log(privilegeImage)
    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 300);
  }, []);

  return (
    <Card>
      <CardHeader title="Create Privilege"></CardHeader>
      <CardBody>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <div className="d-flex justify-content-between">
                <Typography className={classes.instructions}>All steps completed</Typography>
                <Button onClick={handleReset}>Reset</Button>
              </div>
            ) : (
              <div>
                <div className={classes.instructions}>{getStepContent(activeStep)}</div>
                <div className="d-flex justify-content-between">
                  <Button
                    onClick={handleBack}
                    className={classes.backButton}
                  >
                    Back
                  </Button>
                  <Button variant="contained" className="btn btn-primary" onClick={handleNext}>
                    {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};
